import { Transition } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { hrEmployeesAtom } from '../../../../../atoms/atoms';

function Subheader() {
  const employees = useRecoilValue(hrEmployeesAtom);
  const [state, setState] = useState({
    title: 'My Employees',
    subtitle: 'List of my employees waiting for compensation.',
  });

  useEffect(() => {
    switch (employees.tab) {
      case '2':
        return setState({
          title: 'Approved by Human Resources',
          subtitle: 'List of employees approved by Human Resources.',
        });
      case '3':
        return setState({
          title: 'Unapproved by Human Resources',
          subtitle: 'List of employees unapproved by Human Resources.',
        });
      case '4':
        return setState({
          title: 'Employees Summary',
          subtitle: 'Overall employees status summary.',
        });
      default:
        return setState({
          title: 'My Employees',
          subtitle: 'List of my employees waiting for compensation.',
        });
    }
  }, [employees.tab]);

  return (
    <div className="p-4 border-b border-neutral-100">
      <Transition
        show={!!state}
        appear={!!state}
        enter="transform transition duration-1000"
        enterFrom="opacity-0 translate-x-1/4"
        enterTo="opacity-100 translate-x-0"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 scale-95 translate-x-1/4"
      >
        <div className="text-lg font-extrabold tracking-tight">
          {state.title}
        </div>
        <div className="text-sm text-neutral-400">{state.subtitle}</div>
      </Transition>
    </div>
  );
}

export default Subheader;

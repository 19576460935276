import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardAtom } from '../../../../../../../atoms/atoms';

function Progress({ percent }) {
  const state = useRecoilValue(dashboardAtom);

  return (
    <div className="space-y-4">
      <div
        className={classNames(
          ' w-full bg-neutral-200 rounded-full h-2.5',
          percent < 100 && 'animate-pulse'
        )}
      >
        <div
          className="bg-gradient-to-r from-orange-100 to-orange-500 h-2.5 rounded-full"
          style={{
            width: `${_.round((percent / state.checkboxes?.length) * 100, 2)}%`,
          }}
        />
      </div>
      <div className="flex flex-col items-center text-xs font-semibold text-neutral-500">
        <div className="animate-pulse lowercase">
          Progress... {_.round((percent / state.checkboxes?.length) * 100, 2)}%
        </div>
      </div>
    </div>
  );
}

export default Progress;

import { useMsal } from '@azure/msal-react';
import { Transition } from '@headlessui/react';
import {
  CheckIcon,
  CurrencyDollarIcon,
  NoSymbolIcon,
  PencilIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import axios from 'axios';
import { AnimatePresence, motion } from 'framer-motion';
import is from 'is_js';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../../../../atoms/atoms';
import {
  getEmployees,
  numberWithCommas,
} from '../../../../../../../utils/helpers';
import ProRate from '../../../../../../shared-components/ProRate';
import Steps from '../../../../../../shared-components/steps/Steps';
import Empty from '../../Empty';
import Footer from '../../Footer';
import ItemsHeader from '../../ItemsHeader';
import Subheader from '../../Subheader';
import Toolbar from '../../Toolbar';

function Tab1() {
  const { accounts } = useMsal();
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);

  const updateEmployees = () => {
    axios
      .put('/api/staff-hr-update-selected', {
        uuid: _.map(employees.checkboxes, (e) => {
          return e.uuid;
        }),
        name: accounts[0]?.name,
      })
      .then(async () => {
        const response = await getEmployees();
        if (response.data) {
          setEmployees({
            ...employees,
            loading: false,
            data: response.data,
            error: null,
            checked: false,
            checkboxes: [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const updateEmployee = (uuid, approval, percent, comments) => {
    axios
      .put('/api/hr-staff-update', {
        uuid,
        approval,
        percent,
        comments: approval ? comments : null,
        name: approval ? accounts[0]?.name : null,
      })
      .then(async (r) => {
        const map = _.map(employees.data, (object) => {
          if (object.uuid === r.data[0].uuid) {
            return {
              ...object,
              supervisor_approval: r.data[0].supervisor_approval,
              supervisor_approval_date: r.data[0].supervisor_approval_date,
              final_compensation: r.data[0].final_compensation,
              percent: r.data[0].percent,
              hr_approval: r.data[0].hr_approval,
              hr_approval_date: r.data[0].hr_approval_date,
              rejected: r.data[0].rejected,
              supervisor_comments: r.data[0].supervisor_comments,
              supervisor_approval_name: r.data[0].supervisor_approval_name,
              hr_approval_name: r.data[0].hr_approval_name,
            };
          }
          return object;
        });

        await setEmployees({
          ...employees,
          data: map,
          checkboxes: [],
          checked: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employees.data) {
      const subset = _.chain(employees.data)
        .filter({ supervisor_email: accounts[0].username })
        .filter((e) => (employees.underPaid ? e.percent < 100 : true))
        .filter((e) => (employees.proRate ? e.payment === 'Pro Rate' : true))
        .value();

      setEmployees({
        ...employees,
        subset,
      });
    }
  }, [employees.data, employees.underPaid, employees.proRate]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 1 }}
      className="flex flex-col flex-1"
    >
      <Subheader />
      <Toolbar />
      <ItemsHeader />
      <div className="flex flex-col flex-1 divide-y divide-neutral-100 space-y-">
        {employees.subset &&
        matchSorter(
          employees.filter === ''
            ? employees.subset
            : _.filter(employees.subset, ['supervisor', employees.filter]),
          employees.search,
          {
            keys: ['name'],
          }
        ).length > 0 ? (
          _.map(
            matchSorter(
              employees.filter === ''
                ? employees.subset
                : _.filter(employees.subset, ['supervisor', employees.filter]),
              employees.search,
              {
                keys: ['name'],
              }
            ),
            (employee) => {
              return (
                <div
                  key={employee.uuid}
                  className="flex flex-1 items-center justify-center divide-x divide-neutral-100 text-sm"
                >
                  <div className="flex items-center justify-center w-10">
                    <input
                      id={employee.name}
                      name={employee.name}
                      value={employee.name}
                      checked={is.existy(
                        _.find(employees.checkboxes, employee)
                      )}
                      disabled={employee.ceo_approval}
                      onChange={(event) => {
                        if (event.target.checked) {
                          setEmployees(() => ({
                            ...employees,
                            checkboxes: [...employees.checkboxes, employee],
                          }));
                        } else {
                          const filter = _.filter(
                            employees.checkboxes,
                            (object) => {
                              return object.name !== event.target.value;
                            }
                          );
                          setEmployees(() => ({
                            ...employees,
                            checkboxes: filter,
                          }));
                        }
                      }}
                      type="checkbox"
                      className="border-neutral-200 disabled:opacity-50 disabled:cursor-not-allowed rounded-full text-orange-400 cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none"
                    />
                  </div>
                  <div className="flex flex-1 flex-col font-light">
                    <div
                      className={`${
                        employee.rejected && 'bg-rose-100 text-rose-500'
                      } flex items-center space-x-4 bg-neutral-50 h-16 px-4`}
                    >
                      <div className="flex flex-col flex-1 justify-center font-bold text-sm md:text-base tracking-tight">
                        <div>{employee.name}</div>
                        <div className="text-xs font-normal text-neutral-400 space-x-2">
                          ID: {employee.number} / {employee.email}
                        </div>
                      </div>
                      <AnimatePresence>
                        {employee.rejected && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="w-32 flex leading-none items-center justify-center text-rose-600 text-xs font-normal py-1 space-x-1 bg-rose-200 rounded-full"
                          >
                            <NoSymbolIcon className="w-4 h-4 leading-none" />
                            <div className="leading-none text-xs">
                              Needs Revision
                            </div>
                          </motion.div>
                        )}
                        {employee.ceo_approval && (
                          <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="w-32 flex leading-none items-center justify-center text-green-600 text-xs font-normal py-1 space-x-1 bg-green-200 rounded-full"
                          >
                            <CurrencyDollarIcon className="w-4 h-4" />
                            <div className="leading-none text-xs">
                              Ready to Pay
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </div>
                    <Steps employee={employee} />
                    <div className="p-4">
                      <ProRate payment={employee.payment} />
                      <div className="flex flex-col text-xs text-neutral-500">
                        <div>
                          Compensation:{' '}
                          <span className="text-neutral-700 font-medium">
                            ${numberWithCommas(employee.compensation)}
                          </span>
                        </div>
                        <div>
                          Compensation Percent:{' '}
                          <span className="text-neutral-700 font-medium">
                            {employee.percent}%
                          </span>
                        </div>
                        <div>
                          Final Compensation:{' '}
                          {employee.final_compensation <
                          employee.compensation ? (
                            <span className="text-rose-500 font-medium">
                              ${numberWithCommas(employee.final_compensation)}
                            </span>
                          ) : (
                            <span className="text-neutral-700 font-medium">
                              ${numberWithCommas(employee.final_compensation)}
                            </span>
                          )}
                        </div>
                        {employee.supervisor_comments && (
                          <div>
                            Supervisor comments: {employee.supervisor_comments}
                          </div>
                        )}
                        {employee.hr_comments && (
                          <div>
                            Human Resources comments: {employee.hr_comments}
                          </div>
                        )}
                        {employee.ceo_comments && (
                          <div>CEO comments: {employee.ceo_comments}</div>
                        )}
                      </div>
                      <div className="flex items-center space-x-3 mt-4">
                        <button
                          type="button"
                          disabled={employee.ceo_approval}
                          onClick={() => {
                            setEmployees({
                              ...employees,
                              open: true,
                              employee,
                              as: 'staff',
                            });
                          }}
                          className="flex items-center justify-center text-orange-300 bg-neutral-600 hover:ring-2 ring-orange-300 disabled:opacity-25 disabled:cursor-not-allowed p-2 rounded transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                        >
                          <PencilIcon className="h-5 w-auto" />
                        </button>
                        <Transition
                          appear={!employee.supervisor_approval}
                          show={!employee.supervisor_approval}
                          enter="transition transform duration-500 delay-700"
                          enterFrom="opacity-0 -translate-x-full"
                          enterTo="opacity-100 translate-x-0"
                          leave="transition transform duration-200"
                          leaveFrom="opacity-100 translate-x-0"
                          leaveTo="opacity-0 translate-x-full"
                        >
                          <button
                            type="button"
                            disabled={employee.ceo_approval}
                            onClick={() => {
                              updateEmployee(
                                employee.uuid,
                                true,
                                employee.percent,
                                ''
                              );
                            }}
                            className="flex items-center justify-center text-emerald-400 bg-neutral-600 hover:ring-2 ring-emerald-400 disabled:opacity-25 disabled:cursor-not-allowed p-2 rounded transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                          >
                            <CheckIcon className="h-5 w-auto" />
                          </button>
                        </Transition>
                        <Transition
                          appear={employee.supervisor_approval}
                          show={employee.supervisor_approval}
                          enter="transition transform duration-500 delay-700"
                          enterFrom="opacity-0 -translate-x-full"
                          enterTo="opacity-100 translate-x-0"
                          leave="transition transform duration-200"
                          leaveFrom="opacity-100 translate-x-0"
                          leaveTo="opacity-0 translate-x-full"
                        >
                          <button
                            type="button"
                            disabled={employee.ceo_approval}
                            onClick={() => {
                              updateEmployee(employee.uuid, false, 100, '');
                            }}
                            className="flex items-center justify-center text-rose-400 bg-neutral-600 hover:ring-2 ring-rose-400 disabled:opacity-25 disabled:cursor-not-allowed p-2 rounded transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                          >
                            <XMarkIcon className="h-5 w-auto" />
                          </button>
                        </Transition>
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          )
        ) : (
          <Empty />
        )}
        <Footer />
      </div>
      <Transition
        show={!!employees.checkboxes.length}
        enter="transition transform duration-500"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition transform duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
        className="fixed inset-x-0 bottom-0"
      >
        <button
          type="button"
          onClick={updateEmployees}
          className="w-full text-sm bg-neutral-800 bg-opacity-80 hover:bg-opacity-90 text-white font-medium px-4 h-20 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
        >
          Approve Selected
        </button>
      </Transition>
    </motion.div>
  );
}

export default Tab1;

import { AnimatePresence } from 'framer-motion';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../atoms/atoms';
import { getEmployees } from '../../../../utils/helpers';
import Alert from './shared-components/Alert';
import Comments from './shared-components/Comments';
import Header from './shared-components/Header';
import Modal from './shared-components/Modal';
import TabBar from './shared-components/TabBar';
import Tab1 from './shared-components/tabs/tab1/Tab1';
import Tab2 from './shared-components/tabs/tab2/Tab2';
import Tab3 from './shared-components/tabs/tab3/Tab3';
import Tab4 from './shared-components/tabs/tab4/Tab4';

function HumanResources() {
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);

  useMount(async () => {
    const response = await getEmployees();

    if (response.data) {
      setEmployees({
        ...employees,
        data: response.data,
      });
    }
  });

  return (
    <div className="flex flex-col flex-1 container mx-auto px-4 md:px-0 overflow-hidden space-y-6 pb-6">
      <Header />
      <Modal />
      <Alert />
      <Comments />
      <div className="flex flex-col flex-1 bg-white rounded-xl">
        <TabBar />
        <AnimatePresence>
          {employees.tab === '1' && <Tab1 />}
          {employees.tab === '2' && <Tab2 />}
          {employees.tab === '3' && <Tab3 />}
          {employees.tab === '4' && <Tab4 />}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default HumanResources;

import {
  BanknotesIcon,
  HomeIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import roles from '../utils/roles';

const navigation = [
  {
    key: 'home',
    name: 'Home',
    type: 'item',
    path: '/',
    icon: HomeIcon,
    auth: roles.user,
    index: true,
  },
  {
    key: 'compensation',
    name: 'Variable Compensation',
    type: 'group',
    path: 'compensation',
    icon: BanknotesIcon,
    auth: roles.user,
    children: [
      {
        key: 'staff',
        name: 'Variable Compensation',
        type: 'item',
        path: '/compensation/staff',
        icon: UserGroupIcon,
        auth: roles.staff,
        subtitle: 'Staff',
      },
      {
        key: 'human-resources',
        name: 'Variable Compensation ',
        type: 'item',
        path: '/compensation/human-resources',
        icon: UserGroupIcon,
        auth: roles.human_resources,
        subtitle: 'Human Resources',
      },
      {
        key: 'ceo',
        name: 'Variable Compensation',
        path: '/compensation/ceo',
        type: 'item',
        icon: UserGroupIcon,
        auth: roles.ceo,
        subtitle: 'CEO',
      },
      {
        key: 'requests-dashboard',
        name: 'Dashboard',
        path: '/compensation/dashboard',
        type: 'item',
        icon: PresentationChartLineIcon,
        auth: roles.admin,
        subtitle: null,
      },
    ],
  },
];

export default navigation;

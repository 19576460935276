import { useMsal } from '@azure/msal-react';
import { Navigate } from 'react-router-dom';
import { hasPermission } from '../../utils/helpers';

function ProtectedRoute({ children, role }) {
  const { accounts } = useMsal();

  if (!hasPermission(role, accounts[0]?.idTokenClaims.roles[0])) {
    return <Navigate to="/" replace />;
  }

  return children;
}

export default ProtectedRoute;

import {
  Document,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import dayjs from 'dayjs';
import React from 'react';
import hrLogo from '../../../assets/img/hr-logo.png';
import logo from '../../../assets/img/logo-prst.png';
import { date, numberWithCommas } from '../../../utils/helpers';

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 40,
  },
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  main: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'left',
  },
  section: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 18,
    marginBottom: 40,
    textAlign: 'center',
  },
  text: {
    marginBottom: 12,
    fontSize: 14,
  },
  name: {
    fontSize: 14,
  },
  position: {
    fontSize: 12,
    color: '#5D5D5D',
  },
  date: {
    fontSize: 12,
  },
  image: {
    height: 42.81,
    width: 150,
  },
  divider: {
    height: 2,
    backgroundColor: '#000000',
    marginBottom: 40,
  },
});

export function Report({ data }) {
  return (
    <Document>
      <Page style={styles.body} wrap>
        <View style={styles.header}>
          <Image
            style={{
              height: 42.81,
              width: 150,
            }}
            src={logo}
          />
          <Image
            style={{
              height: 42.81,
              width: 159.33,
            }}
            src={hrLogo}
          />
        </View>
        <View>
          <Text style={styles.title}>VARIABLE COMPENSATION</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.container}>
          <View>
            <View style={styles.section}>
              <Text style={styles.text}>Employee: {data?.name}</Text>
              <Text style={styles.text}>Supervisor: {data?.supervisor}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>Position: {data?.position}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>File Number: {data?.number}</Text>
              <Text style={styles.text}>
                Year:{' '}
                {dayjs(data?.completed_at).format('YYYY') || 'Not Approved'}
              </Text>
            </View>
          </View>
          <View style={styles.main}>
            <View style={styles.section}>
              <Text style={styles.text}>
                Max. Variable Compensation: $
                {numberWithCommas(data?.compensation)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>
                Variable Compensation Percentage: {data?.percent}%
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>
                Final Variable Compensation: $
                {numberWithCommas(data?.final_compensation)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.text}>
                Comments: {data?.supervisor_comments}
              </Text>
            </View>
          </View>
          <View>
            <View style={styles.section}>
              <Text style={styles.text}>Approved by:</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.text}>
                <Text style={styles.name}>
                  {data?.supervisor_approval_name || 'Not Approved'}
                </Text>
                <Text style={styles.position}>Supervisor</Text>
              </View>
              <Text style={styles.date}>
                {data?.supervisor_approval_date
                  ? date(data?.supervisor_approval_date)
                  : 'Not Approved'}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.text}>
                <Text style={styles.name}>
                  {data?.hr_approval_name || 'Not Approved'}
                </Text>
                <Text style={styles.position}>Human Resources Director</Text>
              </View>
              <Text style={styles.date}>
                {data?.hr_approval_date
                  ? date(data?.hr_approval_date)
                  : 'Not Approved'}
              </Text>
            </View>
            <View style={styles.section}>
              <View style={styles.text}>
                <Text style={styles.name}>
                  {data?.ceo_approval_name || 'Not Approved'}
                </Text>
                <Text style={styles.position}>Chief Executive Officer </Text>
              </View>
              <Text style={styles.date}>
                {data?.ceo_approval_date
                  ? date(data?.ceo_approval_date)
                  : 'Not Approved'}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}

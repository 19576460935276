import { useMsal } from '@azure/msal-react';
import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import React from 'react';
import { useRecoilState } from 'recoil';
import { ceoEmployeesAtom } from '../../../../../atoms/atoms';
import { sendCEORejectMail, sendRejectMail } from '../../../../../utils/graph';
import { getEmployees } from '../../../../../utils/helpers';

function Alert() {
  const { instance } = useMsal();
  const [employees, setEmployees] = useRecoilState(ceoEmployeesAtom);

  const onClick = () => {
    axios
      .put('/api/cancel', {
        uuid: employees.alertEmployee?.uuid,
      })
      .then(async () => {
        const response = await getEmployees();
        if (response.data) {
          setEmployees({
            ...employees,
            loading: false,
            data: response.data,
            error: null,
            checked: false,
            checkboxes: [],
            alert: false,
          });

          instance
            .acquireTokenSilent({
              scopes: ['Mail.Send'],
            })
            .then((r) => {
              sendCEORejectMail(r?.accessToken, employees.alertEmployee)
                .then((res) => console.log(res))
                .catch((err) => console.log(err));
            })
            .catch(() => {
              instance
                .acquireTokenRedirect({
                  scopes: ['Mail.Send'],
                })
                .then((r) => {
                  sendRejectMail(r?.accessToken, employees.alertEmployee)
                    .then((res) => console.log(res))
                    .catch((err) => console.log(err));
                });
            });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Transition
      appear
      show={employees.alert}
      as={React.Fragment}
      afterLeave={() => setEmployees({ ...employees, alertEmployee: null })}
    >
      <Dialog
        static
        open={employees.alert}
        onClose={() => {
          setEmployees({ ...employees, alert: false });
        }}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen p-4 text-center text-neutral-700 font-sans">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200 delay-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className="p-4 text-center">
                <div className="text-2xl font-extrabold tracking-tight mb-4 uppercase">
                  Warning
                </div>
                <div className="text-sm">
                  Are you sure you like to reject the supervisor approval for{' '}
                  {employees.alertEmployee?.name}? This process is irreversible.
                  If you agree, please click the reject button or the cancel
                  button if you disagree.
                </div>
              </div>
              <div className="flex items-center justify-end py-4 space-x-2 bg-neutral-100 p-4">
                <button
                  type="button"
                  onClick={() => {
                    setEmployees({ ...employees, alert: false });
                  }}
                  className="text-xs text-white font-medium w-20 py-3 rounded bg-neutral-300 hover:bg-neutral-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={onClick}
                  className="text-xs text-white font-medium w-20 py-3 rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Reject
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Alert;

import React from 'react';

function Count({ count, total }) {
  return (
    <div className="flex flex-col justify-center">
      <div className="text-neutral-500">
        Showing <span className="font-semibold text-neutral-700">{count}</span>{' '}
        employees of{' '}
        <span className="font-semibold text-neutral-700">{total}</span>
      </div>
    </div>
  );
}

export default Count;

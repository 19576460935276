import { useMsal } from '@azure/msal-react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import React, { useState } from 'react';
import { useMount } from 'react-use';
import { getUserProfilePicture } from '../../utils/graph';

function Avatar({ item }) {
  const { instance } = useMsal();
  const [photo, setPhoto] = useState(null);

  const getPhoto = () => {
    instance
      .acquireTokenSilent({
        scopes: ['User.Read'],
      })
      .then(async (r) => {
        const response = await getUserProfilePicture(
          r.accessToken,
          item.email || item.mail
        );

        if (response) {
          const fileReaderInstance = new FileReader();
          fileReaderInstance.readAsDataURL(response);
          fileReaderInstance.onload = () => {
            setPhoto(fileReaderInstance.result);
          };
        }
      });
  };

  useMount(getPhoto);

  return (
    <div className="flex items-center justify-center h-10 w-10 flex-shrink-0 bg-white border border-dashed rounded-full">
      {photo ? (
        <img alt="Avatar" src={photo} className="h-10 w-10 rounded-full" />
      ) : (
        <UserCircleIcon className="text-neutral-200 h-8 w-9 rounded-full" />
      )}
    </div>
  );
}

export default Avatar;

import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { hrEmployeesAtom } from '../../../../../atoms/atoms';

function Footer() {
  const employees = useRecoilValue(hrEmployeesAtom);

  return (
    <div className="bg-neutral-700 text-white rounded-b-xl flex items-center px-4 h-16 space-x-1 text-xs border-t border-neutral-100 tracking-tight">
      <span>Showing</span>
      <span>
        {
          matchSorter(
            employees.filter === ''
              ? employees.subset
              : _.filter(employees.subset, ['supervisor', employees.filter]),
            employees.search,
            {
              keys: ['name'],
            }
          ).length
        }
      </span>
      <span>employees of {employees.subset?.length}</span>
    </div>
  );
}

export default Footer;

import { useMsal } from '@azure/msal-react';
import { Transition } from '@headlessui/react';
import { Bars4Icon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { useRecoilState, useSetRecoilState } from 'recoil';
import logo from '../../../../../assets/img/logo.svg';
import {
  ceoAtom,
  hrAtom,
  sidebarAtom,
  tokenAtom,
  userAtom,
} from '../../../../../atoms/atoms';
import { callCEO, callHR, callMe } from '../../../../../utils/graph';
import Profile from './Profile';

function Header() {
  const { instance } = useMsal();
  const [sidebar, setSidebar] = useRecoilState(sidebarAtom);
  const [user, setUser] = useRecoilState(userAtom);
  const setToken = useSetRecoilState(tokenAtom);
  const setCEO = useSetRecoilState(ceoAtom);
  const setHR = useSetRecoilState(hrAtom);

  useMount(() => {
    instance
      .acquireTokenSilent({
        scopes: [
          'User.Read',
          'User.Read.All',
          'Mail.Send',
          'Directory.ReadWrite.All',
        ],
      })
      .then((r) => {
        setToken(r?.accessToken);
        callMe(r?.accessToken)
          .then((res) => setUser(res.data))
          .catch((err) => console.error(err));
        callCEO(r?.accessToken)
          .then((res) => setCEO(res.data.value))
          .catch((err) => console.error(err));
        callHR(r?.accessToken)
          .then((res) => setHR(res.data.value))
          .catch((err) => console.error(err));
      })
      .catch(() => {
        instance
          .acquireTokenRedirect({
            scopes: [
              'User.Read',
              'User.Read.All',
              'Mail.Send',
              'Directory.ReadWrite.All',
            ],
          })
          .then((r) => {
            setToken(r?.accessToken);
            callMe(r?.accessToken)
              .then((res) => setUser(res.data))
              .catch((err) => console.error(err));
            callCEO(r?.accessToken)
              .then((res) => setCEO(res.data.value))
              .catch((err) => console.error(err));
            callHR(r?.accessToken)
              .then((res) => setHR(res.data.value))
              .catch((err) => console.error(err));
          });
      });
  });

  return (
    <header
      className={classNames(
        'sticky top-0 z-20 shadow flex flex-grow-0 flex-shrink-0 items-center justify-between bg-orange-400 text-white px-4 h-20'
      )}
    >
      <Transition
        show
        appear
        enter="transition transform duration-500"
        enterFrom="opacity-0 -translate-x-full"
        enterTo="opacity-100 translate-x-0"
        leave="transition transform duration-500"
        leaveFrom="opacity-100 translate-x-0"
        leaveTo="opacity-0 -translate-x-full"
      >
        <Link
          to="/"
          className="ring-0 focus:ring-0 outline-none focus:outline-none"
        >
          <img
            key="image"
            alt="logo"
            src={logo}
            className="h-10 transform hover:scale-105 transition duration-500 ease-in-out"
          />
        </Link>
      </Transition>
      <div className="flex flex-row items-center justify-center space-x-4">
        {user && (
          <Transition
            show
            appear
            enter="transition transform duration-500"
            enterFrom="opacity-0 translate-x-full"
            enterTo="opacity-100 -translate-x-0"
            leave="transition transform duration-500"
            leaveFrom="opacity-100 -translate-x-0"
            leaveTo="opacity-0 translate-x-full"
            className="text-xs"
          >
            <Profile />
          </Transition>
        )}
        <button
          type="button"
          onClick={() => setSidebar(!sidebar)}
          className="hover:opacity-75 outline-none focus:outline-none right-0 focus:ring-0 transition ease-in-out duration-500"
        >
          <Bars4Icon className="w-6 h-6" />
        </button>
      </div>
    </header>
  );
}

export default Header;

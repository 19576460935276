import { EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { RouterProvider } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { router } from '../navigation/router';
import instance from '../providers/auth';

const accounts = instance.getAllAccounts();

if (accounts.length > 0) {
  instance.setActiveAccount(accounts[0]);
}

instance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const { account } = event.payload;
    instance.setActiveAccount(account);
  }
});

function App() {
  return (
    <RecoilRoot>
      <MsalProvider instance={instance}>
        <RouterProvider router={router} />
      </MsalProvider>
    </RecoilRoot>
  );
}

export default App;

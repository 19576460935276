import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useRecoilState } from 'recoil';
import { staffEmployeesAtom } from '../../../../../atoms/atoms';

function Toolbar() {
  const [employees, setEmployees] = useRecoilState(staffEmployeesAtom);

  return (
    <div className="flex flex-row border-b border-neutral-100 bg-white rounded-t-xl divide-x divide-neutral-100">
      <div className="flex flex-1 items-center h-16 px-4">
        <div className="flex items-center justify-center mr-4">
          <MagnifyingGlassIcon className="w-5 h-5 text-neutral-40 " />
        </div>
        <input
          id="search"
          name="search"
          type="search"
          placeholder="Search employee by name..."
          value={employees.search}
          onChange={(e) => {
            setEmployees({ ...employees, search: e.target.value });
          }}
          className="flex-1 bg-transparent w-full text-neutral-700 border-0 placeholder-neutral-400 rounded text-sm focus:ring-0 p-0"
        />
      </div>
    </div>
  );
}

export default Toolbar;

import { ArrowsUpDownIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardAtom } from '../../../../../../../../atoms/atoms';

export function TableHeader({
  width,
  title,
  handleSort,
  over,
  titleClassName,
}) {
  const state = useRecoilValue(dashboardAtom);

  return (
    <div
      className={classNames(
        width,
        'relative p-4 flex items-center justify-center h-12 bg-neutral-50 font-semibold'
      )}
    >
      <div className={classNames('flex-1 truncate', titleClassName)}>
        {title}
      </div>
      {over && (
        <button
          type="button"
          onClick={handleSort}
          className="flex items-center justify-center absolute shadow right-4 hover:text-neutral-400  ring-0 focus:ring-0 outline-none focus:outline-none h-6 w-6 bg-neutral-100 rounded-full"
        >
          {state.over === over ? (
            <ChevronDownIcon
              className={`${
                state.order === 'asc' ? 'rotate-0' : 'rotate-180'
              } w-3 h-3 transform transition duration-500 ease-in-out`}
            />
          ) : (
            <ArrowsUpDownIcon className="w-3 h-3 transition duration-500 ease-in-out" />
          )}
        </button>
      )}
    </div>
  );
}

import { AuthenticatedTemplate } from '@azure/msal-react';
import { Toaster } from 'react-hot-toast';
import { useOutlet } from 'react-router-dom';
import Header from './header/Header';
import Sidebar from './Sidebar';

function Authenticated() {
  const outlet = useOutlet();

  return (
    <AuthenticatedTemplate>
      <Toaster />
      <div className="min-h-screen flex flex-col bg-neutral-100 text-neutral-700 font-sans">
        <Header />
        <Sidebar />
        {outlet}
      </div>
    </AuthenticatedTemplate>
  );
}

export default Authenticated;

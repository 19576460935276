import { useMsal } from '@azure/msal-react';
import { Popover, Transition } from '@headlessui/react';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import _ from 'lodash';
import { Fragment } from 'react';
import { useRecoilState } from 'recoil';
import { userAtom } from '../../../../../atoms/atoms';
import Avatar from '../../../Avatar';

function Profile() {
  const { instance, accounts } = useMsal();
  const [user, setUser] = useRecoilState(userAtom);
  const handleLogout = () => {
    instance
      .logoutRedirect()
      .then(() => setUser(null))
      .catch((e) => console.error(e));
  };

  return (
    <Popover as="div" className="relative">
      {({ close }) => (
        <>
          <div>
            <Popover.Button className="flex items-center py-2 px-2 justify-center rounded-md hover:bg-orange-300 hover:bg-opacity-40 text-[11px] outline-none focus:outline-none right-0 focus:ring-0 transition ease-in-out duration-500 space-x-2">
              <span className="sr-only">Opn user menu</span>
              <div className="text-right uppercase font-medium">
                <div>{user.mail}</div>
                <div className="text-[9px]">
                  Role:{' '}
                  {_.replace(accounts[0]?.idTokenClaims.roles[0], '_', ' ')}
                </div>
              </div>
              <Avatar item={user} />
            </Popover.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="text-neutral-700 absolute right-0 z-10 w-screen max-w-xs origin-top-right rounded-md bg-white shadow-lg outline-none ring-0 focus:ring-0 focus:outline-none">
              <div className="p-4 space-y-4">
                <div className="text-[10px] text-neutral-500 text-right">
                  {user.officeLocation}
                </div>
                <div className="flex items-center space-x-2 text-neutral-700">
                  <Avatar item={user} />
                  <div>
                    <div className="font-semibold text-orange-400">
                      {user.displayName}
                    </div>
                    <div className="text-neutral-500">{user.mail}</div>
                    <div className="text-neutral-500">{user.jobTitle}</div>
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={() => {
                  close();
                  handleLogout();
                }}
                className="text-orange-400 flex items-center space-x-2 w-full font-medium bg-orange-50 p-4 rounded-b-md hover:bg-orange-100 outline-none focus:outline-none right-0 focus:ring-0 transition ease-in-out duration-500"
              >
                <ArrowRightOnRectangleIcon className="w-5 h-5" />
                <div>Sign Out</div>
              </button>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default Profile;

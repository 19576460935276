import axios from 'axios';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import _ from 'lodash';

dayjs.extend(localizedFormat);

export const getEmployees = async () => {
  try {
    return await axios.get('/api/employees');
  } catch (err) {
    console.log(err.message);
  }
};

export const getMyEmployees = async (email) => {
  try {
    return await axios.post('/api/my-employees', {
      email,
    });
  } catch (err) {
    console.log(err.message);
  }
};

export const status = (e) => {
  let type;

  if (e.supervisor_approval && !e.hr_approval && !e.ceo_approval) {
    type = 'Waiting for human resources approval';
  } else if (e.supervisor_approval && e.hr_approval && !e.ceo_approval) {
    type = 'Waiting for CEO approval';
  } else if (e.hr_approval && e.hr_approval && e.ceo_approval) {
    type = 'Approved by CEO';
  } else {
    type = 'Waiting for supervisor approval';
  }

  return type;
};

export const date = (string) => {
  return dayjs(string).format('LLL');
};

export const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  return '';
};

export const hasPermission = (array, role) => {
  return _.includes(array, role);
};

export const exportEmployees = (data, title) => {
  const items = _.map(data, (e) => {
    return {
      'Co Code': e.code,
      'Batch ID': 'VCPAY',
      'File #': e.number,
      'Tax Frequency': 9,
      'Earnings 5 Code': 'VAR',
      'Earnings 5 Amount': e.final_compensation,
    };
  });

  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(items[0]);
  let csv = items.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  );
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  const downloadLink = document.createElement('a');
  const blob = new Blob([csv]);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${title || 'employees'}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportAllEmployees = (data, title) => {
  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(data[0]);
  let csv = data.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  );
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  const downloadLink = document.createElement('a');
  const blob = new Blob([csv]);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${title || 'employees'}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const exportEmployee = (data, title) => {
  const replacer = (key, value) => (value === null ? '' : value);
  const header = Object.keys(data[0]);
  let csv = data.map((row) =>
    header
      .map((fieldName) => JSON.stringify(row[fieldName], replacer))
      .join(',')
  );
  csv.unshift(header.join(','));
  csv = csv.join('\r\n');

  const downloadLink = document.createElement('a');
  const blob = new Blob([csv]);
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = `${title || 'employees'}.csv`;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const round = (num) => {
  const m = Number((Math.abs(num) * 100).toPrecision(15));
  return (Math.round(m) / 100) * Math.sign(num);
};

export const getUnapprovedEmployees = async () => {
  try {
    const response = await axios.get('/api/unapproved-employees');
    if (response.data?.length > 0) {
      exportAllEmployees(response.data, 'unapproved');
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/unapproved-employees)': ${error.message}`
    );
  }
};

export const getSupervisorApprovedEmployees = async () => {
  try {
    const response = await axios.get('/api/supervisor-approved-employees');
    if (response.data?.length > 0) {
      const items = _.map(response.data, (e) => {
        return {
          'Position ID': `${e.code}${e.number}`,
          'Employee Name': e.name,
          'Approval Date': dayjs(e.supervisor_approval_date).format(
            'MM/DD/YYYY h:mm a'
          ),
        };
      });

      exportAllEmployees(items, 'approved-employees');
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/unapproved-employees)': ${error.message}`
    );
  }
};

export const getApprovedEmployees = async () => {
  try {
    const response = await axios.get('/api/approved-employees');
    if (response.data?.length > 0) {
      exportAllEmployees(response.data, 'approved-employees');
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/unapproved-employees)': ${error.message}`
    );
  }
};

export const getPRCCIApprovedEmployees = async () => {
  try {
    const response = await axios.get('/api/prcci-approved-employees');
    if (response.data?.length > 0) {
      exportEmployees(response.data, 'PRqqmEPI');
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/unapproved-employees)': ${error.message}`
    );
  }
};

export const getPRSTApprovedEmployees = async () => {
  try {
    const response = await axios.get('/api/prst-approved-employees');
    if (response.data?.length > 0) {
      exportEmployees(response.data, 'PRlcxEPI');
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/unapproved-employees)': ${error.message}`
    );
  }
};

export const getEmployee = async (uuid, name) => {
  try {
    const response = await axios.get(`/api/employee/${uuid}`);
    if (response.data?.length > 0) {
      exportEmployee(response.data, name);
    }
  } catch (error) {
    throw new Error(
      `Error in 'axiosGetJsonData(/api/employee/${uuid})': ${error.message}`
    );
  }
};

export const setTravelRequest = async (values) => {
  try {
    const response = await axios.post('/api/travel-request', values);
    if (response.data?.length > 0) {
      return response.data;
    }
  } catch (error) {
    throw new Error(`Error in travel your request: ${error.message}`);
  }
};

export const getTravelRequests = async () => {
  try {
    const response = await axios.get('/api/travel-requests');
    if (response.data?.length > 0) {
      return response.data;
    }
  } catch (error) {
    throw new Error(`Error fetching the travel requests: ${error.message}`);
  }
};

import { AnimatePresence } from 'framer-motion';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';
import { ceoEmployeesAtom } from '../../../../atoms/atoms';
import { getEmployees } from '../../../../utils/helpers';
import Alert from './shared-components/Alert';
import Header from './shared-components/Header';
import Modal from './shared-components/Modal';
import TabBar from './shared-components/TabBar';
import Tab1 from './shared-components/tabs/tab1/Tab1';
import Tab2 from './shared-components/tabs/tab2/Tab2';
import Tab3 from './shared-components/tabs/tab3/Tab3';
import Tab4 from './shared-components/tabs/tab4/Tab4';

function CEO() {
  const [employees, setEmployees] = useRecoilState(ceoEmployeesAtom);

  useMount(async () => {
    const response = await getEmployees();

    if (response.data) {
      setEmployees({
        ...employees,
        data: response.data,
      });
    }
  });

  // useEffect(() => {
  //   if (employees.data) {
  //     if (employees.tab === '1') {
  //       if (employees.underPaid) {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, (e) => {
  //             return (
  //               e.supervisor_email === accounts[0].username && e.percent < 100
  //             );
  //           }),
  //         });
  //       } else {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, {
  //             supervisor_email: accounts[0].username,
  //           }),
  //         });
  //       }
  //     } else if (employees.tab === '2') {
  //       if (employees.underPaid) {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, (e) => {
  //             return e.ceo_approval && e.percent < 100;
  //           }),
  //         });
  //       } else {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, {
  //             ceo_approval: true,
  //           }),
  //         });
  //       }
  //     } else if (employees.tab === '3') {
  //       if (employees.underPaid) {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, (e) => {
  //             return (
  //               !e.ceo_approval &&
  //               e.supervisor_approval &&
  //               e.hr_approval &&
  //               e.percent < 100
  //             );
  //           }),
  //         });
  //       } else {
  //         setEmployees({
  //           ...employees,
  //           subset: _.filter(employees.data, {
  //             ceo_approval: false,
  //             supervisor_approval: true,
  //             hr_approval: true,
  //           }),
  //         });
  //       }
  //     } else {
  //       setEmployees({
  //         ...employees,
  //         subset: _.filter(employees.data, (e) => {
  //           return employees.underPaid ? e.percent < 100 : e.percent >= 0;
  //         }),
  //       });
  //     }
  //   }
  // }, [employees.tab, employees.data, employees.underPaid]);

  return (
    <div className="flex flex-col flex-1 container mx-auto px-4 md:px-0 overflow-hidden space-y-6 pb-6">
      <Header />
      <Modal />
      <Alert />
      <div className="flex flex-col flex-1 bg-white rounded-xl">
        <TabBar />
        <AnimatePresence>
          {employees.tab === '1' && <Tab1 />}
          {employees.tab === '2' && <Tab2 />}
          {employees.tab === '3' && <Tab3 />}
          {employees.tab === '4' && <Tab4 />}
        </AnimatePresence>
      </div>
    </div>
  );
}

export default CEO;

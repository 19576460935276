import { Transition } from '@headlessui/react';
import { CheckIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React from 'react';

function Header() {
  return (
    <Transition
      show
      appear
      enter="transition transform duration-500"
      enterFrom="opacity-0 translate-x-10"
      enterTo="opacity-100 translate-x-0"
      leave="transition transform duration-500"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-10"
      className="space-y-4"
    >
      <div className="text-2xl font-extrabold tracking-tight mt-10">
        Variable Compensation - Human Resources
      </div>
      <div className="text-sm">
        The employees on this list were recommended by their supervisors to
        receive the variable compensation for this year, according to the
        results of the performance evaluation.
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="">
          <div className="font-bold mb-4">Instructions</div>
          <ul className="space-y-1">
            <li className="flex items-center space-x-2 text-sm">
              <div className="flex flex-shrink-0 items-center justify-center rounded-full text-emerald-400 bg-neutral-600 h-6 w-6">
                <CheckIcon className="inline-block h-3 w-3" />
              </div>
              <div>
                If the variable compensation is 100%, press the green button.
              </div>
            </li>
            <li className="flex items-center space-x-2 text-sm">
              <div className="flex flex-shrink-0 items-center justify-center rounded-full text-rose-400 bg-neutral-600 h-6 w-6">
                <XMarkIcon className="inline-block h-3 w-3" />
              </div>
              <div>
                If you wish to cancel the compensation and start over press the
                red button.
              </div>
            </li>
            <li className="flex items-center space-x-2 text-sm">
              <div className="flex flex-shrink-0 items-center justify-center rounded-full text-orange-300 bg-neutral-600 h-6 w-6">
                <PencilIcon className="inline-block h-3 w-3" />
              </div>
              <div>
                If the amount is less than 100%, press the edit button and
                assign the percentage. Then press approve.
              </div>
            </li>
          </ul>
        </div>
      </div>
    </Transition>
  );
}

export default Header;

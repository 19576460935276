import { useMsal } from '@azure/msal-react';
import Spinner from '../Spinner';
import Authenticated from './shared-components/Authenticated';
import Unauthenticated from './shared-components/Unauthenticated';

function Layout() {
  const { accounts, inProgress } = useMsal();

  if (
    inProgress === 'login' ||
    inProgress === 'logout' ||
    inProgress === 'startup' ||
    inProgress === 'handleRedirect'
  ) {
    return (
      <div className="h-screen bg-orange-400 flex flex-col items-center justify-center text-white">
        <Spinner />
        <div className="text-xs mt-4 animate-pulse">
          Managing Authentication
        </div>
      </div>
    );
  }

  return <>{accounts.length ? <Authenticated /> : <Unauthenticated />}</>;
}

export default Layout;

import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ceoEmployeesAtom } from '../../../../../atoms/atoms';

function Subheader() {
  const employees = useRecoilValue(ceoEmployeesAtom);
  const [state, setState] = useState({
    title: 'My Employees',
    subtitle: 'List of my employees waiting for compensation.',
  });

  useEffect(() => {
    switch (employees.tab) {
      case '2':
        return setState({
          title: 'Approved by CEO',
          subtitle: 'List of employees approved by CEO.',
        });
      case '3':
        return setState({
          title: 'Unapproved by CEO',
          subtitle: 'List of employees unapproved by CEO.',
        });
      case '4':
        return setState({
          title: 'Employees Summary',
          subtitle: 'Overall employees status summary.',
        });
      default:
        return setState({
          title: 'My Employees',
          subtitle: 'List of my employees waiting for compensation.',
        });
    }
  }, [employees.tab]);

  return (
    <div className="p-4 border-b border-neutral-100">
      <div className="text-lg font-extrabold tracking-tight">{state.title}</div>
      <div className="text-sm text-neutral-400">{state.subtitle}</div>
    </div>
  );
}

export default Subheader;

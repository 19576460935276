import {
  UserGroupIcon,
  UserMinusIcon,
  UserPlusIcon,
} from '@heroicons/react/24/outline';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
  addEmployeesAtom,
  deleteEmployeesAtom,
  restoreAtom,
} from '../../../../../../atoms/atoms';

function Actions() {
  const [restore, setRestore] = useRecoilState(restoreAtom);
  const [add, setAdd] = useRecoilState(addEmployeesAtom);
  const [remove, setRemove] = useRecoilState(deleteEmployeesAtom);

  return (
    <div className="flex flex-col flex-1 p-4 space-y-6">
      <div>
        <div className="text-sm text-neutral-100 font-semibold">
          Employees Management
        </div>
        <div className="text-xs text-neutral-500">
          Warning: Every action in this section will modify the employees data
          permanently and irreversible.
        </div>
      </div>
      <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-5 text-white text-xs font-medium">
        <button
          type="button"
          onClick={() => setAdd({ ...add, open: true })}
          className="flex flex-col flex-1 items-center justify-center space-y-2 ring-2 ring-green-500 ring-opacity-60 ring-offset-4 ring-offset-neutral-800 rounded-xl bg-green-500 hover:bg-green-400 transition-colors duration-500 ease-in-out focus:ring-0 outline-none focus:outline-none"
        >
          <UserPlusIcon className="w-5 h-5" />
          <span className="font-medium">ADD NEW</span>
        </button>
        <button
          type="button"
          onClick={() => setRemove({ ...remove, open: true })}
          className="flex flex-col flex-1 items-center justify-center space-y-2 ring-2 ring-rose-500 ring-opacity-60 ring-offset-4 ring-offset-neutral-800 rounded-xl bg-rose-500 hover:bg-rose-400 transition-colors duration-500 ease-in-out focus:ring-0 outline-none focus:outline-none"
        >
          <UserMinusIcon className="w-5 h-5" />
          <span className="font-medium">DELETE</span>
        </button>
        <button
          type="button"
          onClick={() => setRestore({ ...restore, open: true })}
          className="flex flex-col flex-1 items-center justify-center space-y-2 ring-2 ring-sky-500 ring-opacity-60 ring-offset-4 ring-offset-neutral-800 rounded-xl bg-sky-500 hover:bg-sky-400 transition-colors duration-500 ease-in-out focus:ring-0 outline-none focus:outline-none"
        >
          <UserGroupIcon className="w-5 h-5" />
          <span className="font-medium">RESTORE</span>
        </button>
      </div>
    </div>
  );
}

export default Actions;

import { atom } from 'recoil';

export const staffEmployeesAtom = atom({
  key: 'staffEmployees',
  default: {
    data: null,
    subset: [],
    loading: false,
    error: null,
    open: false,
    employee: null,
    search: '',
    checked: false,
    checkboxes: [],
    tab: '1',
    filter: '',
    as: 'staff',
    order: 'asc',
    over: 'name',
    underPaid: false,
    proRate: false,
  },
});

export const hrEmployeesAtom = atom({
  key: 'hrEmployees',
  default: {
    data: null,
    subset: [],
    loading: false,
    error: null,
    open: false,
    employee: null,
    search: '',
    checked: false,
    checkboxes: [],
    tab: '1',
    filter: '',
    as: 'hr',
    order: 'asc',
    over: 'name',
    alert: false,
    alertEmployee: null,
    report: false,
    reportAll: false,
    reportEmployee: null,
    underPaid: false,
    proRate: false,
    comments: false,
    commentsEmployee: null,
  },
});

export const ceoEmployeesAtom = atom({
  key: 'ceoEmployees',
  default: {
    data: null,
    subset: [],
    loading: false,
    error: null,
    open: false,
    employee: null,
    search: '',
    checked: false,
    checkboxes: [],
    tab: '1',
    filter: '',
    as: 'staff',
    order: 'asc',
    over: 'name',
    alert: false,
    alertEmployee: null,
    report: false,
    reportEmployee: null,
    underPaid: false,
    proRate: false,
  },
});

export const dashboardAtom = atom({
  key: 'requests-dashboard',
  default: {
    data: null,
    search: '',
    filter: '',
    order: 'asc',
    over: 'name',
    underPaid: false,
    proRate: false,
    checked: false,
    checkboxes: [],
    current: null,
    modal: false,
    photo: null,
  },
});

export const sidebarAtom = atom({
  key: 'sidebar',
  default: false,
});

export const notificationsAtom = atom({
  key: 'notifications',
  default: [],
});

export const tokenAtom = atom({
  key: 'token',
  default: null,
});

export const userAtom = atom({
  key: 'user',
  default: null,
});

export const photoAtom = atom({
  key: 'photo',
  default: null,
});

export const chartAtom = atom({
  key: 'charts',
  default: null,
});

export const ceoAtom = atom({
  key: 'ceo',
  default: null,
});

export const hrAtom = atom({
  key: 'hr',
  default: null,
});

export const restoreAtom = atom({
  key: 'restore-employees',
  default: {
    open: false,
    employees: null,
    file: null,
    loading: false,
    error: null,
  },
});

export const addEmployeesAtom = atom({
  key: 'add-employees',
  default: {
    open: false,
    employees: null,
    file: null,
    loading: false,
    error: null,
  },
});

export const deleteEmployeesAtom = atom({
  key: 'delete-employees',
  default: {
    open: false,
    employees: [],
    loading: false,
    error: null,
  },
});

import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';
import Divider from './Divider';

function Step({ step, title, subtitle, status, divider, notes }) {
  return (
    <li className="flex flex-1 items-center h-20 relative overflow-hidden bg-white">
      <div className="group text-xs pl-8 pr-4 flex items-center text-xs">
        <span className="flex-shrink-0">
          {status === 'Completed' && (
            <span className="flex-shrink-0">
              <span className="w-8 h-8 flex items-center justify-center bg-green-400 rounded-full">
                <CheckIcon className="w-5 h-5 text-white" />
              </span>
            </span>
          )}
          {status === 'In Progress' && (
            <span className="flex-shrink-0">
              <span className="w-8 h-8 flex items-center justify-center bg-neutral-700 rounded-full animate-pulse">
                <span className="text-white font-semibold">{step}</span>
              </span>
            </span>
          )}
          {status === 'Waiting for previous action to be completed' && (
            <span className="flex-shrink-0">
              <span className="w-8 h-8 flex items-center justify-center border border-neutral-300 rounded-full">
                <span className="text-neutral-400 font-semibold">{step}</span>
              </span>
            </span>
          )}
        </span>
        <span className="mt-0.5 ml-4 min-w-0 flex flex-col">
          {status === 'Completed' && (
            <span className="text-xs font-bold tracking-tight uppercase text-green-400">
              {title}
            </span>
          )}
          {status === 'In Progress' && (
            <span className="text-xs font-bold tracking-tight uppercase text-neutral-700 animate-pulse">
              {title}
            </span>
          )}
          {status === 'Waiting for previous action to be completed' && (
            <span className="text-xs font-bold tracking-tight uppercase text-neutral-400">
              {title}
            </span>
          )}
          <span className="text-xs text-neutral-400">{subtitle}</span>
          <span className="text-xs text-neutral-400">{notes}</span>
        </span>
      </div>
      {divider && <Divider />}
    </li>
  );
}

export default Step;

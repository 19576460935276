import { Client } from '@microsoft/microsoft-graph-client';
import axios from 'axios';

export async function getUserProfilePicture(accessToken, email) {
  const client = Client.init({
    authProvider: (done) => {
      done(null, accessToken);
    },
  });

  try {
    return await client.api(`/users/${email}/photo/$value`).get();
  } catch (error) {
    return null;
  }
}

export async function searchUsers(token, query) {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    },
  });

  try {
    return await client
      .api(`/users`)
      .filter(
        `startswith(displayName,'${query}') or startswith(givenName,'${query}') or startswith(surname,'${query}') or startswith(mail,'${query}') or startswith(userPrincipalName,'${query}')`
      )
      .count(true)
      .get();
  } catch (error) {
    return error;
  }
}

export async function setManager(token, userID, managerID) {
  const client = Client.init({
    authProvider: (done) => {
      done(null, token);
    },
  });

  try {
    return await client.api(`/users/${userID}/manager/$ref`).put({
      '@odata.id': `https://graph.microsoft.com/v1.0/users/${managerID}`,
    });
  } catch (error) {
    throw error;
  }
}

export async function callMe(accessToken) {
  return axios
    .get('https://graph.microsoft.com/v1.0/me', {
      method: 'get',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function callCEO(accessToken) {
  return axios
    .get(
      "https://graph.microsoft.com/v1.0/users?$filter=jobTitle eq 'Chief Executive Officer'",
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function callHR(accessToken) {
  return axios
    .get(
      "https://graph.microsoft.com/v1.0/users?$filter=jobTitle eq 'Human Resources Director'",
      {
        method: 'get',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendRejectMail(token, employee) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject: `Variable compensation of ${employee.name} has been rejected by Human Resources`,
          body: {
            contentType: 'Text',
            content: `The variable compensation of ${
              employee.name
            } has been rejected by Human Resources at ${new Date().toLocaleDateString()}. Please go to ${
              window.location.origin
            }/compensation/staff and make corrections to employee variable compensation.`,
          },
          toRecipients: [
            { emailAddress: { address: employee.supervisor_email } },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendCEORejectMail(token, employee) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject: `Variable compensation of ${employee.name} has been rejected by Human Resources`,
          body: {
            contentType: 'Text',
            content: `The variable compensation of ${
              employee.name
            } has been rejected by the CEO at ${new Date().toLocaleDateString()}. Please go to ${
              window.location.origin
            }/compensation/staff and make corrections to employee variable compensation.`,
          },
          toRecipients: [
            { emailAddress: { address: 'ialvarez@prsciencetrust.org' } },
            { emailAddress: { address: employee.supervisor_email } },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendMail(token, employee) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject: `Variable compensation of ${employee.name} has been approved by Human Resources`,
          body: {
            contentType: 'Text',
            content: `The variable compensation of ${
              employee.name
            } has been approved by Human Resources at ${new Date().toLocaleDateString()}.`,
          },
          toRecipients: [
            { emailAddress: { address: employee.supervisor_email } },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendRequestMail(token, manager, id) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject: `New employee requested by ${manager}`,
          body: {
            contentType: 'Text',
            content: `A request for a new employee has been submitted. Please follow this link to access the request: ${window.location.origin}/employees/requests/${id}.`,
          },
          toRecipients: [
            { emailAddress: { address: 'mjimenez@prsciencetrust.org' } },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendCeoMail(token, employee) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject: `The variable compensation of ${employee.name} has been approved by the CEO`,
          body: {
            contentType: 'Text',
            content: `The variable compensation of ${
              employee.name
            } has been approved by the CEO at ${new Date().toLocaleDateString()}.`,
          },
          toRecipients: [
            {
              emailAddress: {
                address: 'ialvarez@prsciencetrust.org',
              },
            },
          ],
          ccRecipients: [
            {
              emailAddress: {
                address: 'kcolon@prsciencetrust.org',
              },
            },
          ],
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

export async function sendEmail(
  token,
  subject,
  content,
  toRecipients,
  ccRecipients
) {
  return axios
    .post(
      'https://graph.microsoft.com/v1.0/me/sendMail',
      {
        message: {
          subject,
          body: {
            contentType: 'Text',
            content,
          },
          toRecipients,
          ccRecipients,
        },
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error;
    });
}

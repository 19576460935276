import { Menu } from '@headlessui/react';
import {
  CheckBadgeIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  NoSymbolIcon,
  PrinterIcon,
  ReceiptPercentIcon,
  UserCircleIcon,
  UsersIcon,
  ScissorsIcon,
} from '@heroicons/react/24/outline';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../../atoms/atoms';
import {
  getApprovedEmployees,
  getPRCCIApprovedEmployees,
  getPRSTApprovedEmployees,
  getSupervisorApprovedEmployees,
  getUnapprovedEmployees,
} from '../../../../../utils/helpers';

function Toolbar() {
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);
  const [group, setGroup] = useState([]);

  useEffect(() => {
    if (employees.data && employees.data.length > 0) {
      let array = [];

      _.mapKeys(
        _.countBy(
          matchSorter(
            employees.filter === ''
              ? employees.data
              : _.filter(employees.data, ['supervisor', employees.filter]),
            employees.search,
            {
              keys: ['name'],
            }
          ),
          'supervisor'
        ),
        (value, key) => {
          if (key !== '') {
            array = _.concat(array, { name: key, count: value });
          }
        }
      );

      setGroup(array);
    }
  }, [employees.data, employees.search]);

  return (
    <div className="flex flex-row border-b border-neutral-100 bg-white rounded-t-xl divide-x divide-neutral-100">
      <div className="flex flex-1 items-center h-16 px-4">
        <div className="flex items-center justify-center mr-4">
          <MagnifyingGlassIcon className="w-5 h-5 text-neutral-40 " />
        </div>
        <input
          id="search"
          name="search"
          type="search"
          placeholder="Search employee by name..."
          value={employees.search}
          onChange={(e) => {
            setEmployees({ ...employees, search: e.target.value });
          }}
          className="flex-1 bg-transparent w-full text-neutral-700 border-0 placeholder-neutral-400 rounded text-sm focus:ring-0 p-0"
        />
      </div>
      <div className="relative inline-block text-left">
        <button
          type="button"
          onClick={() => {
            setEmployees({
              ...employees,
              proRate: !employees.proRate,
            });
          }}
          className={classNames(
            'hover:text-neutral-500 flex items-center justify-center h-full w-14 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out',
            employees.proRate && 'text-sky-500 hover:text-sky-500'
          )}
        >
          <ReceiptPercentIcon className="w-5 h-5" />
        </button>
      </div>
      <div className="relative inline-block text-left">
        <button
          type="button"
          onClick={() => {
            setEmployees({
              ...employees,
              underPaid: !employees.underPaid,
            });
          }}
          className={classNames(
            'hover:text-neutral-500 flex items-center justify-center h-full w-14 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out',
            employees.underPaid && 'text-sky-500 hover:text-sky-500'
          )}
        >
          <ScissorsIcon className="w-5 h-5" />
        </button>
      </div>
      {employees.tab > 1 && (
        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="hover:text-neutral-500 flex items-center justify-center h-full w-14 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
            <FunnelIcon className="w-5 h-5" />
          </Menu.Button>
          <Menu.Items className="absolute z-40 overflow-y-auto scrollbar-hide p-2 space-y-2 max-h-72 right-0 w-72 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-0 focus:ring-0 outline-none focus:outline-none">
            {_.map(group, (item) => {
              return (
                <Menu.Item
                  key={item.name}
                  as="button"
                  type="button"
                  onClick={() => {
                    if (employees.filter === item.name) {
                      setEmployees({ ...employees, filter: '' });
                    } else {
                      setEmployees({ ...employees, filter: item.name });
                    }
                  }}
                  className={`
                            ${
                              employees.filter === item.name && 'bg-neutral-50'
                            } w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50
                          `}
                >
                  <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-orange-400 bg-orange-100 rounded sm:h-12 sm:w-12">
                    <UserCircleIcon className="w-7 h-7" />
                    {employees.filter === item.name && (
                      <div className="absolute -right-2 -top-2">
                        <CheckCircleIcon className="w-4 h-4 text-rose-500" />
                      </div>
                    )}
                  </div>
                  <div className="ml-4">
                    <div className="text-left font-medium">{item.name}</div>
                    <div className="text-left text-neutral-500">
                      {item.count} {item.count > 1 ? 'employees' : 'employee'}
                    </div>
                  </div>
                </Menu.Item>
              );
            })}
          </Menu.Items>
        </Menu>
      )}
      <Menu as="div" className="relative inline-block text-left">
        <Menu.Button className="hover:text-neutral-500 flex items-center justify-center h-full w-14 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out">
          <PrinterIcon className="w-5 h-5" />
        </Menu.Button>
        <Menu.Items className="absolute z-40 overflow-y-auto scrollbar-hide p-2 space-y-2 max-h-72 right-0 w-72 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-0 focus:ring-0 outline-none focus:outline-none">
          <Menu.Item
            as="button"
            type="button"
            onClick={getPRSTApprovedEmployees}
            className="w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-orange-400 bg-orange-100 rounded sm:h-12 sm:w-12">
              <UsersIcon className="w-7 h-7" />
            </div>
            <div className="ml-4">
              <div className="text-left font-medium">PRST Employees</div>
              <div className="text-left text-neutral-500">
                PRST employees approved by CEO
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            as="button"
            type="button"
            onClick={getPRCCIApprovedEmployees}
            className="w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-cyan-400 bg-cyan-100 rounded sm:h-12 sm:w-12">
              <UsersIcon className="w-7 h-7" />
            </div>
            <div className="ml-4">
              <div className="text-left font-medium">PRCCI Employees</div>
              <div className="text-left text-neutral-500">
                PRCCI employees approved by CEO
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            as="button"
            type="button"
            onClick={getSupervisorApprovedEmployees}
            className="w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-amber-400 bg-amber-100 rounded sm:h-12 sm:w-12">
              <CheckBadgeIcon className="w-7 h-7" />
            </div>
            <div className="ml-4">
              <div className="text-left font-medium">Supervisor Approvals</div>
              <div className="text-left text-neutral-500">
                Employees approved by supervisor
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            as="button"
            type="button"
            onClick={getApprovedEmployees}
            className="w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-lime-400 bg-lime-100 rounded sm:h-12 sm:w-12">
              <CheckBadgeIcon className="w-7 h-7" />
            </div>
            <div className="ml-4">
              <div className="text-left font-medium">Approved Employees</div>
              <div className="text-left text-neutral-500">
                Employees approved by CEO
              </div>
            </div>
          </Menu.Item>
          <Menu.Item
            as="button"
            type="button"
            onClick={getUnapprovedEmployees}
            className="w-full p-2 text-xs flex items-center transition duration-150 ease-in-out rounded-lg hover:bg-neutral-50 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
          >
            <div className="relative flex items-center justify-center flex-shrink-0 w-10 h-10 text-rose-400 bg-rose-100 rounded sm:h-12 sm:w-12">
              <NoSymbolIcon className="w-7 h-7" />
            </div>
            <div className="ml-4">
              <div className="text-left font-medium">Unapproved Employees</div>
              <div className="text-left text-neutral-500">
                Employees waiting for approval
              </div>
            </div>
          </Menu.Item>
        </Menu.Items>
      </Menu>
    </div>
  );
}

export default Toolbar;

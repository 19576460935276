import illustration from '../../../assets/img/not_found.svg';

function NotFound() {
  return (
    <>
      <div className="flex flex-1 items-center justify-center bg-white py-12">
        <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
          <div className="flex flex-shrink-0 justify-center">
            <img
              className="h-48 w-auto"
              src={illustration}
              alt="Illustration"
            />
          </div>
          <div className="py-16">
            <div className="text-center">
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-neutral-700 sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-neutral-500">
                Sorry, we could not find the page you’re looking for.
              </p>
              <div className="mt-6">
                <a
                  href="/"
                  className="text-base font-medium text-orange-400 hover:text-orange-500"
                >
                  Go back home
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}

export default NotFound;

import React from 'react';

function Divider() {
  return (
    <div className="absolute top-0 left-0 w-3 inset-0 block" aria-hidden="true">
      <svg
        className="h-full w-full text-neutral-200"
        viewBox="0 0 12 82"
        fill="none"
        preserveAspectRatio="none"
      >
        <path
          d="M0.5 0V31L10.5 41L0.5 51V82"
          stroke="currentcolor"
          vectorEffect="non-scaling-stroke"
        />
      </svg>
    </div>
  );
}

export default Divider;

import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import CEO from '../components/pages/compensation/ceo/CEO';
import Compensation from '../components/pages/compensation/Compensation';
import Dashboard from '../components/pages/compensation/dashboard/Dashboard';
import HumanResources from '../components/pages/compensation/hr/HumanResources';
import Staff from '../components/pages/compensation/staff/Staff';
import Home from '../components/pages/home/Home';
import NotFound from '../components/pages/notFound/NotFound';
import Layout from '../components/shared-components/layout/Layout';
import ProtectedRoute from '../components/shared-components/ProtectedRoute';
import roles from '../utils/roles';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<Home />} />
      <Route path="compensation" element={<Compensation />}>
        <Route
          path="staff"
          element={
            <ProtectedRoute role={roles.staff}>
              <Staff />
            </ProtectedRoute>
          }
        />
        <Route
          path="human-resources"
          element={
            <ProtectedRoute role={roles.human_resources}>
              <HumanResources />
            </ProtectedRoute>
          }
        />
        <Route
          path="ceo"
          element={
            <ProtectedRoute role={roles.ceo}>
              <CEO />
            </ProtectedRoute>
          }
        />
        <Route
          path="dashboard"
          element={
            <ProtectedRoute role={roles.admin}>
              <Dashboard />
            </ProtectedRoute>
          }
        />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

import _ from 'lodash';
import React, { useEffect } from 'react';
import { defaults } from 'react-chartjs-2';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ceoEmployeesAtom, chartAtom } from '../../../../../../atoms/atoms';
import CeoChart from './shared-components/CeoChart';
import HumChart from './shared-components/HumChart';
import SupChart from './shared-components/SupChart';
import TotalChart from './shared-components/TotalChart';

defaults.plugins.legend.display = false;
defaults.animation.duration = 3000;
defaults.animation.delay = 0.25;

function Charts() {
  const employees = useRecoilValue(ceoEmployeesAtom);
  const setData = useSetRecoilState(chartAtom);

  useEffect(() => {
    if (employees.data) {
      const object = {
        ceo_approved: _.round(
          (_.sumBy(employees.data, (o) => {
            return o.ceo_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        ceo_unapproved: _.round(
          (_.sumBy(employees.data, (o) => {
            return !o.ceo_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        hr_approved: _.round(
          (_.sumBy(employees.data, (o) => {
            return o.hr_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        hr_unapproved: _.round(
          (_.sumBy(employees.data, (o) => {
            return !o.hr_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        sup_approved: _.round(
          (_.sumBy(employees.data, (o) => {
            return o.supervisor_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        sup_unapproved: _.round(
          (_.sumBy(employees.data, (o) => {
            return !o.supervisor_approval;
          }) /
            employees.data.length) *
            100,
          0
        ),
        totalCompensation: _.sumBy(employees.data, (o) => {
          return parseFloat(o.compensation);
        }),
        paidCompensation: _.chain(employees.data)
          .filter((item) => item.ceo_approval)
          .sumBy((item) => parseFloat(item.final_compensation))
          .value(),
        total: employees.data.length,
      };
      setData(object);
    }
  }, [employees.data]);

  return (
    <div className="grid grid-cols-1 md:grid-cols-4 divide-x divide-neutral-100">
      <SupChart />
      <HumChart />
      <CeoChart />
      <TotalChart />
    </div>
  );
}

export default Charts;

import { UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { ArrowRightOnRectangleIcon } from '@heroicons/react/24/outline';
import logo from '../../../../assets/img/icon.svg';

function Unauthenticated() {
  const { instance } = useMsal();

  const handleLogin = () => {
    instance
      .loginRedirect({
        scopes: [
          'User.Read',
          'User.Read.All',
          'Mail.Send',
          'Directory.ReadWrite.All',
        ],
      })
      .catch((e) => console.error(e));
  };

  return (
    <UnauthenticatedTemplate>
      <div className="h-screen flex flex-col items-center justify-center bg-orange-400 text-white">
        <img alt="Logo" src={logo} className="h-20 w-auto mb-4" />
        <div className="text-xs tracking-tight">
          Puerto Rico Science, Technology & Research Trust
        </div>
        <div className="text-base font-medium tracking-tight mb-4">
          Human Resources
        </div>
        <button
          type="button"
          onClick={handleLogin}
          className="flex items-center space-x-1 text-sm border border-white border-opacity-25 hover:border-opacity-50 py-2 px-4 rounded outline-none focus:outline-none ring-0 focus:ring-0 transition duration-500 ease-in-out"
        >
          <ArrowRightOnRectangleIcon className="h-4 w-4" />
          <div className="font-medium text-sm">Login</div>
        </button>
      </div>
    </UnauthenticatedTemplate>
  );
}

export default Unauthenticated;

import {
  ArrowsUpDownIcon,
  CloudArrowDownIcon,
} from '@heroicons/react/24/outline';
import { CheckBadgeIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../../../../atoms/atoms';
import { Report } from '../../../../../../shared-components/report/Report';
import Charts from '../../charts/Charts';
import ItemsHeader from '../../ItemsHeader';
import Subheader from '../../Subheader';
import Toolbar from '../../Toolbar';

function Tab4() {
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);

  const handleSort = (over) => {
    if (employees.over === over) {
      if (employees.order === 'asc') {
        setEmployees({
          ...employees,
          over,
          order: 'desc',
        });
      } else {
        setEmployees({
          ...employees,
          over,
          order: 'asc',
        });
      }
    } else {
      setEmployees({
        ...employees,
        over,
        order: 'asc',
      });
    }
  };

  const generateReport = async (e) => {
    const blob = await pdf(<Report data={e} />).toBlob();
    await saveAs(blob, `${e.name}.pdf`);
  };

  useEffect(() => {
    if (employees.data) {
      const subset = _.chain(employees.data)
        .filter((e) => (employees.underPaid ? e.percent < 100 : true))
        .filter((e) => (employees.proRate ? e.payment === 'Pro Rate' : true))
        .value();

      setEmployees({
        ...employees,
        subset,
      });
    }
  }, [employees.data, employees.underPaid, employees.proRate]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 1 }}
      className="divide-y divide-neutral-100 text-xs"
    >
      <Subheader />
      <Charts />
      <Toolbar />
      <ItemsHeader />
      <div className="flex items-center h-12 bg-neutral-50 font-semibold">
        <div className="w-2/5 md:w-1/5 p-4 flex items-center justify-start">
          <div className="flex-1">Employee</div>
          <button
            type="button"
            onClick={() => handleSort('name')}
            className="hover:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            {employees.over === 'name' ? (
              <ChevronDownIcon
                className={`${
                  employees.order === 'asc' ? 'rotate-0' : 'rotate-180'
                } w-5 h-5 transform transition duration-500 ease-in-out`}
              />
            ) : (
              <ArrowsUpDownIcon className="text-neutral-200 hover:text-neutral-300 w-5 h-5 transition duration-500 ease-in-out" />
            )}
          </button>
        </div>
        <div className="w-1/5 p-4 hidden md:flex items-center justify-start">
          <div className="flex-1">Supervisor</div>
          <button
            type="button"
            onClick={() => handleSort('supervisor')}
            className="hover:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            {employees.over === 'supervisor' ? (
              <ChevronDownIcon
                className={`${
                  employees.order === 'asc' ? 'rotate-0' : 'rotate-180'
                } w-5 h-5 transform transition duration-500 ease-in-out`}
              />
            ) : (
              <ArrowsUpDownIcon className="text-neutral-200 hover:text-neutral-300 w-5 h-5 transition duration-500 ease-in-out" />
            )}
          </button>
        </div>
        <div className="w-1/5 p-4 flex items-center justify-center space-x-2">
          <div className="flex-1 truncate">Supervisor</div>
          <button
            type="button"
            onClick={() => handleSort('supervisor_approval')}
            className="hover:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            {employees.over === 'supervisor_approval' ? (
              <ChevronDownIcon
                className={`${
                  employees.order === 'asc' ? 'rotate-0' : 'rotate-180'
                } w-5 h-5 transform transition duration-500 ease-in-out`}
              />
            ) : (
              <ArrowsUpDownIcon className="text-neutral-200 hover:text-neutral-300 w-5 h-5 transition duration-500 ease-in-out" />
            )}
          </button>
        </div>
        <div className="w-1/5 p-4 flex items-center justify-center space-x-2">
          <div className="flex-1 truncate">Human Resources</div>
          <button
            type="button"
            onClick={() => handleSort('hr_approval')}
            className="hover:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            {employees.over === 'hr_approval' ? (
              <ChevronDownIcon
                className={`${
                  employees.order === 'asc' ? 'rotate-0' : 'rotate-180'
                } w-5 h-5 transform transition duration-500 ease-in-out`}
              />
            ) : (
              <ArrowsUpDownIcon className="text-neutral-200 hover:text-neutral-300 w-5 h-5 transition duration-500 ease-in-out" />
            )}
          </button>
        </div>
        <div className="w-1/5 p-4 flex items-center justify-center space-x-2">
          <div className="flex-1 truncate">CEO</div>
          <button
            type="button"
            onClick={() => handleSort('ceo_approval')}
            className="hover:text-neutral-400 ring-0 focus:ring-0 outline-none focus:outline-none"
          >
            {employees.over === 'ceo_approval' ? (
              <ChevronDownIcon
                className={`${
                  employees.order === 'asc' ? 'rotate-0' : 'rotate-180'
                } w-5 h-5 transform transition duration-500 ease-in-out`}
              />
            ) : (
              <ArrowsUpDownIcon className="text-neutral-200 hover:text-neutral-300 w-5 h-5 transition duration-500 ease-in-out" />
            )}
          </button>
        </div>
      </div>
      {employees.loading &&
        _.times(168, (x) => (
          <div
            key={x}
            className="flex items-center text-neutral-500 divide-x divide-neutral-100"
          >
            <div className="w-1/5 px-4 py-5 bg-neutral-50">
              <div className="animate-pulse bg-neutral-200 h-2 w-full rounded-full" />
            </div>
            <div className="w-1/5 px-4 py-5">
              <div className="animate-pulse bg-neutral-200 h-2 w-full rounded-full" />
            </div>
            <div className="w-1/5 flex justify-center px-4 py-5">
              <div className="animate-pulse bg-neutral-200 h-2 w-5 rounded-full" />
            </div>
            <div className="w-1/5 flex justify-center px-4 py-5">
              <div className="animate-pulse bg-neutral-200 h-2 w-5 rounded-full" />
            </div>
            <div className="w-1/5 flex justify-center px-4 py-5">
              <div className="animate-pulse bg-neutral-200 h-2 w-5 rounded-full" />
            </div>
          </div>
        ))}
      {_.map(
        matchSorter(
          employees.filter === ''
            ? employees.subset
            : _.filter(employees.subset, ['supervisor', employees.filter]),
          employees.search,
          {
            keys: ['name'],
          }
        ),
        (e) => {
          return (
            <div
              key={e.uuid}
              className="flex items-center text-neutral-500 divide-x divide-neutral-100"
            >
              <div className="w-2/5 md:w-1/5 p-4 flex flex-col md:flex-row md:items-center justify-start font-medium text-neutral-700 bg-neutral-50">
                <div className="flex items-center space-x-2">
                  <button
                    type="button"
                    onClick={() => generateReport(e)}
                    className="text-blue-500 hover:text-blue-400 transition duration-500 eae-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                  >
                    <CloudArrowDownIcon className="w-4 h-4" />
                  </button>
                  <div>{e.name}</div>
                </div>
                <div className="flex md:hidden text-left txt-xs font-normal text-neutral-400">
                  Sup: {e.supervisor}
                </div>
              </div>
              <div className="w-1/5 p-4 hidden md:flex items-center justify-start">
                {e.supervisor}
              </div>
              <div className="w-1/5 p-4 flex items-center justify-center">
                <CheckBadgeIcon
                  className={`${
                    e.supervisor_approval
                      ? 'text-emerald-400'
                      : 'text-neutral-300'
                  } w-5 h-5`}
                />
              </div>
              <div className="w-1/5 p-4 flex items-center justify-center">
                <CheckBadgeIcon
                  className={`${
                    e.hr_approval ? 'text-emerald-400' : 'text-neutral-300'
                  } w-5 h-5`}
                />
              </div>
              <div className="w-1/5 p-4 flex items-center justify-center">
                <CheckBadgeIcon
                  className={`${
                    e.ceo_approval ? 'text-emerald-400' : 'text-neutral-300'
                  } w-5 h-5`}
                />
              </div>
            </div>
          );
        }
      )}
    </motion.div>
  );
}

export default Tab4;

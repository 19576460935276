import { Dialog, Transition } from '@headlessui/react';
import axios from 'axios';
import _ from 'lodash';
import 'rc-slider/assets/index.css';
import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../../atoms/atoms';
import { date, numberWithCommas } from '../../../../../utils/helpers';

function Comments() {
  const [comments, setComments] = useState('');
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);

  useEffect(() => {
    if (employees.comments) {
      setComments(employees.commentsEmployee.hr_comments || '');
    }
  }, [employees.comments]);

  const onClick = () => {
    axios
      .put('/api/hr-comments', {
        uuid: employees.commentsEmployee.uuid,
        comments: comments || null,
      })
      .then(async (r) => {
        const map = _.map(employees.data, (object) => {
          if (object.uuid === r.data[0].uuid) {
            return {
              ...r.data[0],
            };
          }
          return object;
        });
        await setEmployees({
          ...employees,
          data: map,
          comments: false,
        });
      })
      .catch((err) => console.log(err));
  };

  return (
    <Transition appear show={employees.comments} as={React.Fragment}>
      <Dialog
        static
        open={employees.comments}
        onClose={() => setEmployees({ ...employees, comments: false })}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen p-4 text-center text-neutral-700 font-sans">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200 delay-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => {
              setEmployees({
                ...employees,
                commentsEmployee: null,
              });
            }}
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <Dialog.Title className="text-2xl font-extrabold tracking-tight border-b p-4">
                <div>{employees.commentsEmployee?.name}</div>
                <div className="text-neutral-400 text-xs font-normal">
                  ID: {employees.commentsEmployee?.number} / E-mail:{' '}
                  {employees.commentsEmployee?.email}
                </div>
              </Dialog.Title>
              <div className="p-4 bg-neutral-100 text-neutral-700 text-xs tracking-tight text-right">
                <div>
                  Max variable compensation: $
                  {numberWithCommas(employees.commentsEmployee?.compensation)}
                </div>
                <div>
                  Approved on:{' '}
                  {employees.commentsEmployee?.supervisor_approval_date
                    ? date(employees.commentsEmployee?.supervisor_approval_date)
                    : 'Waiting for your approval'}
                </div>
              </div>
              <div className="p-4">
                <div className="font-medium text-sm">
                  Human Resources Comments
                  <span className="text-red-500">*</span>
                </div>
                <div className="mb-2 text-xs text-neutral-400">
                  The maximum characters count for your comments is 255.
                </div>
                <textarea
                  name="comments"
                  rows={4}
                  maxLength={255}
                  value={comments}
                  onChange={(e) => {
                    const string = _.replace(e.target.value, /\s\s+/g, ' ');
                    setComments(string);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') event.preventDefault();
                  }}
                  className="w-full rounded-xl text-sm border-neutral-200 focus:border-orange-300 focus:ring-orange-300 focus:ring-offset-2 focus:ring-2"
                  placeholder="Comments..."
                />
                <div className="italic text-xs text-neutral-400">
                  {comments?.length || 0} characters
                </div>
              </div>
              <div className="flex items-center justify-end py-4 space-x-2 bg-neutral-100 p-4">
                <button
                  type="button"
                  onClick={() => {
                    setEmployees({ ...employees, comments: false });
                  }}
                  className="text-xs text-white font-medium w-20 py-3 rounded bg-neutral-500 hover:bg-neutral-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={onClick}
                  className="text-xs text-white font-medium w-20 py-3 rounded disabled:opacity-50 disabled:cursor-not-allowed bg-orange-400 hover:bg-orange-300 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Approve
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Comments;

import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useMsal } from '@azure/msal-react';
import { useMount } from 'react-use';
import { motion } from 'framer-motion';
import Footer from './shared-components/Footer';
import Toolbar from './shared-components/Toolbar';
import Header from './shared-components/Header';
import Subheader from './shared-components/Subheader';
import ItemsHeader from './shared-components/ItemsHeader';
import Items from './shared-components/Items';
import { staffEmployeesAtom } from '../../../../atoms/atoms';
import { getMyEmployees } from '../../../../utils/helpers';
import Modal from './shared-components/Modal';

function Staff() {
  const { accounts } = useMsal();
  const [employees, setEmployees] = useRecoilState(staffEmployeesAtom);

  useMount(async () => {
    const response = await getMyEmployees(accounts[0].username);

    if (response.data) {
      setEmployees({
        ...employees,
        data: response.data,
      });
    }
  });

  useEffect(() => {
    if (employees.data) {
      setEmployees({ ...employees, subset: employees.data });
    }
  }, [employees.data]);

  return (
    <div className="flex flex-col flex-1 container mx-auto px-4 md:px-0 overflow-hidden space-y-6 pb-6">
      <Header />
      <Modal />
      <motion.div
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, scale: 0 }}
        transition={{ duration: 1 }}
        className="flex flex-col flex-1 bg-white rounded-xl"
      >
        <Subheader />
        <Toolbar />
        <ItemsHeader />
        <Items />
        <Footer />
      </motion.div>
    </div>
  );
}

export default Staff;

import { Dialog, Transition } from '@headlessui/react';
import { PrinterIcon } from '@heroicons/react/24/outline';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import React, { Fragment, useState } from 'react';
import { useRecoilState } from 'recoil';
import { dashboardAtom } from '../../../../../../atoms/atoms';
import { Report } from '../../../../../shared-components/report/Report';
import Progress from './shared-components/Progress';

function Modal() {
  const [percent, setPercent] = useState(0);
  const [state, setState] = useRecoilState(dashboardAtom);

  const generateReports = async () => {
    let count = 0;
    for (const data of state.checkboxes) {
      const blob = await pdf(<Report data={data} />).toBlob();
      await saveAs(blob, `${data.name}.pdf`);
      count += 1;
      setPercent(count);
      if (count === state.checkboxes.length) {
        setState({
          ...state,
          modal: false,
        });
      }
    }
  };

  return (
    <Transition appear show={state.modal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        onClose={() => {
          setState({
            ...state,
            modal: true,
          });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              afterEnter={generateReports}
              afterLeave={() => setPercent(0)}
            >
              <Dialog.Panel className="space-y-4 w-full max-w-md transform overflow-hidden rounded-3xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="flex items-center justify-between">
                  <div className="flex items-center justify-center bg-orange-100 w-8 h-8 rounded-full">
                    <PrinterIcon className="w-4 h-4 text-orange-400" />
                  </div>
                  <div className="text-right text-xs font-semibold text-neutral-600">
                    Exporting {percent} of {state.data?.length}
                  </div>
                </div>
                <Progress percent={percent} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default Modal;

import { motion } from 'framer-motion';
import React from 'react';
import { useMount } from 'react-use';
import { useRecoilState } from 'recoil';
import { dashboardAtom } from '../../../../atoms/atoms';
import { getEmployees } from '../../../../utils/helpers';
import AddEmployees from './shared-components/actions/shared-components/AddEmployees';
import DeleteEmployees from './shared-components/actions/shared-components/DeleteEmployees';
import RestoreEmployees from './shared-components/actions/shared-components/RestoreEmployees';
import Charts from './shared-components/charts/Charts';
import Items from './shared-components/items/Items';
import Modal from './shared-components/modal/Modal';

function Dashboard() {
  const [state, setState] = useRecoilState(dashboardAtom);

  useMount(async () => {
    const response = await getEmployees();

    setState({
      ...state,
      data: response.data,
    });
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 1 }}
      className="flex-1 container mx-auto bg-white text-xs shadow-md"
    >
      <Modal />
      <RestoreEmployees />
      <AddEmployees />
      <DeleteEmployees />
      <Charts />
      <Items />
    </motion.div>
  );
}

export default Dashboard;

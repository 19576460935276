import { Transition } from '@headlessui/react';
import { CurrencyDollarIcon, XMarkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { motion } from 'framer-motion';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ceoEmployeesAtom } from '../../../../../../../atoms/atoms';
import {
  getEmployees,
  numberWithCommas,
} from '../../../../../../../utils/helpers';
import ProRate from '../../../../../../shared-components/ProRate';
import Steps from '../../../../../../shared-components/steps/Steps';
import Empty from '../../Empty';
import Footer from '../../Footer';
import ItemsHeader from '../../ItemsHeader';
import Subheader from '../../Subheader';
import Toolbar from '../../Toolbar';

function Tab2() {
  const [employees, setEmployees] = useRecoilState(ceoEmployeesAtom);

  const updateEmployees = () => {
    axios
      .put('/api/ceo-update-selected', {
        uuid: _.map(employees.checkboxes, (e) => {
          return e.uuid;
        }),
      })
      .then(async () => {
        const response = await getEmployees();
        if (response.data) {
          setEmployees({
            ...employees,
            loading: false,
            data: response.data,
            error: null,
            checked: false,
            checkboxes: [],
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const updateEmployee = (uuid, approval, percent) => {
    axios
      .put('/api/ceo-update', {
        uuid,
        approval,
        percent,
      })
      .then(async () => {
        const response = await getEmployees();
        if (response.data) {
          setEmployees({
            ...employees,
            loading: false,
            data: response.data,
            error: null,
            checked: false,
            checkboxes: [],
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (employees.data) {
      const subset = _.chain(employees.data)
        .filter({ ceo_approval: true })
        .filter((e) => (employees.underPaid ? e.percent < 100 : true))
        .filter((e) => (employees.proRate ? e.payment === 'Pro Rate' : true))
        .value();

      setEmployees({
        ...employees,
        subset,
      });
    }
  }, [employees.data, employees.underPaid, employees.proRate]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0 }}
      transition={{ duration: 1 }}
      className="flex flex-1 flex-col"
    >
      <Subheader />
      <Toolbar />
      <ItemsHeader />
      <div className="flex flex-1 flex-col divide-y divide-neutral-100 space-y">
        {employees.subset &&
        matchSorter(
          employees.filter === ''
            ? employees.subset
            : _.filter(employees.subset, ['supervisor', employees.filter]),
          employees.search,
          {
            keys: ['name'],
          }
        ).length > 0 ? (
          _.map(
            matchSorter(
              employees.filter === ''
                ? employees.subset
                : _.filter(employees.subset, ['supervisor', employees.filter]),
              employees.search,
              {
                keys: ['name'],
              }
            ),
            (employee) => (
              <div
                key={employee.uuid}
                className="flex flex-1 items-center justify-center divide-x divide-neutral-100 text-sm transition duration-200 ease-in-out"
              >
                <div className="flex flex-1 flex-col font-light">
                  <div className="flex items-center space-x-4 bg-neutral-50 h-16 px-4">
                    <div className="flex flex-col flex-1 justify-center font-bold text-sm md:text-base tracking-tight">
                      <div>{employee.name}</div>
                      <div className="text-xs font-normal text-neutral-400 space-x-2">
                        ID: {employee.number} / {employee.email}
                      </div>
                    </div>
                    {employee.ceo_approval && (
                      <CurrencyDollarIcon className="w-7 h-7 text-green-400" />
                    )}
                  </div>
                  <Steps employee={employee} />
                  <div className="p-4">
                    <ProRate payment={employee.payment} />
                    <div className="flex flex-col text-xs text-neutral-500">
                      <div>
                        Compensation:{' '}
                        <span className="text-neutral-700 font-medium">
                          ${numberWithCommas(employee.compensation)}
                        </span>
                      </div>
                      <div>
                        Compensation Percent:{' '}
                        <span className="text-neutral-700 font-medium">
                          {employee.percent}%
                        </span>
                      </div>
                      <div>
                        Final Compensation:{' '}
                        {employee.final_compensation < employee.compensation ? (
                          <span className="text-rose-500 font-medium">
                            ${numberWithCommas(employee.final_compensation)}
                          </span>
                        ) : (
                          <span className="text-neutral-700 font-medium">
                            ${numberWithCommas(employee.final_compensation)}
                          </span>
                        )}
                      </div>
                      <div>
                        Supervisor:{' '}
                        <span className="text-neutral-700 font-medium">
                          {employee.supervisor}
                        </span>
                      </div>
                      {employee.supervisor_comments && (
                        <div>
                          Supervisor comments: {employee.supervisor_comments}
                        </div>
                      )}
                      {employee.hr_comments && (
                        <div>
                          Human Resources comments: {employee.hr_comments}
                        </div>
                      )}
                      {employee.ceo_comments && (
                        <div>CEO comments: {employee.ceo_comments}</div>
                      )}
                    </div>
                    <div className="flex items-center space-x-3 mt-4">
                      <button
                        type="button"
                        onClick={() => {
                          updateEmployee(
                            employee.uuid,
                            false,
                            employee.percent
                          );
                        }}
                        className="flex items-center justify-center rounded-full text-rose-400 bg-neutral-600 hover:ring-2 ring-rose-400 disabled:opacity-25 disabled:cursor-not-allowed p-2 rounded transition duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        <XMarkIcon className="h-5 w-auto" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <Empty />
        )}
        <Footer />
      </div>
      <Transition
        show={!!employees.checkboxes.length}
        enter="transition transform duration-500"
        enterFrom="opacity-0 translate-y-full"
        enterTo="opacity-100 translate-y-0"
        leave="transition transform duration-500"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-full"
        className="fixed inset-x-0 bottom-0"
      >
        <button
          type="button"
          onClick={updateEmployees}
          className="w-full text-sm bg-neutral-800 bg-opacity-80 hover:bg-opacity-90 text-white font-medium px-4 h-20 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out"
        >
          Approve Selected
        </button>
      </Transition>
    </motion.div>
  );
}

export default Tab2;

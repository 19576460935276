import React from 'react';

function Subheader() {
  return (
    <div className="p-4 border-b border-neutral-100">
      <div className="text-lg font-extrabold tracking-tight">My Employees</div>
      <div className="text-sm text-neutral-400">
        List of my employees waiting for compensation.
      </div>
    </div>
  );
}

export default Subheader;

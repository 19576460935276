import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import { selector } from 'recoil';
import { dashboardAtom } from './atoms';

export const dashboardSelector = selector({
  key: 'requests-dashboard-selector',
  get: ({ get }) => {
    let subset;
    const state = get(dashboardAtom);

    subset = state.data;

    if (state.search !== '') {
      subset = matchSorter(subset, state.search, {
        keys: ['name'],
      });
    }

    if (state.filter !== '') {
      subset = _.filter(subset, ['supervisor', state.filter]);
    }

    if (state.underPaid) {
      subset = _.filter(subset, (e) => {
        return e.percent < 100;
      });
    }

    if (state.proRate) {
      subset = _.filter(subset, (e) => {
        return e.payment === 'Pro Rate';
      });
    }

    return _.orderBy(subset, [state.over], [state.order]);
  },
});

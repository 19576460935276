import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { defaults } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import { dashboardSelector } from '../../../../../../atoms/selectors';
import Actions from '../actions/Actions';
import CeoChart from './shared-components/CeoChart';
import HumChart from './shared-components/HumChart';
import SupChart from './shared-components/SupChart';
import TotalChart from './shared-components/TotalChart';

defaults.plugins.legend.display = false;
defaults.animation.duration = 3000;
defaults.animation.delay = 0.25;

function Charts() {
  const data = useRecoilValue(dashboardSelector);
  const [state, setState] = useState(null);

  useEffect(() => {
    if (data) {
      const object = {
        ceo_approved: _.round(
          (_.sumBy(data, (o) => {
            return o.ceo_approval;
          }) /
            data.length) *
            100,
          0
        ),
        ceo_unapproved: _.round(
          (_.sumBy(data, (o) => {
            return !o.ceo_approval;
          }) /
            data.length) *
            100,
          0
        ),
        hr_approved: _.round(
          (_.sumBy(data, (o) => {
            return o.hr_approval;
          }) /
            data.length) *
            100,
          0
        ),
        hr_unapproved: _.round(
          (_.sumBy(data, (o) => {
            return !o.hr_approval;
          }) /
            data.length) *
            100,
          0
        ),
        sup_approved: _.round(
          (_.sumBy(data, (o) => {
            return o.supervisor_approval;
          }) /
            data.length) *
            100,
          0
        ),
        sup_unapproved: _.round(
          (_.sumBy(data, (o) => {
            return !o.supervisor_approval;
          }) /
            data.length) *
            100,
          0
        ),
        totalCompensation: _.sumBy(data, (o) => {
          return parseFloat(o.compensation);
        }),
        paidCompensation: _.chain(data)
          .filter((item) => item.ceo_approval)
          .sumBy((item) => parseFloat(item.final_compensation))
          .value(),
        total: data.length,
      };
      setState(object);
    }
  }, [data]);

  return (
    <div className="grid grid-cols-1 xl:grid-cols-5 md:grid-cols-2 bg-neutral-800 divide-x divide-neutral-700">
      <SupChart data={state} />
      <HumChart data={state} />
      <CeoChart data={state} />
      <TotalChart data={state} />
      <Actions />
    </div>
  );
}

export default Charts;

import { useMsal } from '@azure/msal-react';
import { Dialog, Disclosure, Transition } from '@headlessui/react';
import { ChevronUpDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import logo from '../../../../assets/img/iconOrange.svg';
import { sidebarAtom, userAtom } from '../../../../atoms/atoms';
import navigation from '../../../../navigation/navigation';
import { hasPermission } from '../../../../utils/helpers';
import Avatar from '../../Avatar';

function Sidebar() {
  const { instance, accounts } = useMsal();
  const [sidebar, setSidebar] = useRecoilState(sidebarAtom);
  const user = useRecoilValue(userAtom);

  const handleLogout = (i) => i.logoutRedirect().catch((e) => console.error(e));

  return (
    <Transition.Root show={sidebar} as={React.Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-hidden"
        open={sidebar}
        onClose={setSidebar}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={React.Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black transition-opacity backdrop-filter backdrop-blur-sm bg-opacity-70" />
          </Transition.Child>
          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={React.Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="relative w-screen max-w-sm">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-in-out duration-500"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-500"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                    <button
                      type="button"
                      className="rounded-md text-white hover:text-white focus:outline-none focus:ring-0"
                      onClick={() => setSidebar(false)}
                    >
                      <span className="sr-only">Close panel</span>
                      <XMarkIcon
                        className="h-6 w-6 transform hover:scale-125 transition duration-500 ease-in-out"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="h-full flex flex-col bg-neutral-800 bg-opacity-70 relative">
                  <div className="flex items-center flex-shrink-0 px-4 bg-neutral-900 backdrop-filter backdrop-blur-sm bg-opacity-50 h-20">
                    <img className="h-10 w-auto" src={logo} alt="Workflow" />
                  </div>
                  {accounts.length && (
                    <nav
                      className="flex-1 text-neutral-200 divide-y divide-neutral-700"
                      aria-label="Sidebar"
                    >
                      {_.map(navigation, (route) => {
                        if (
                          route.type === 'item' &&
                          hasPermission(
                            route.auth,
                            accounts[0].idTokenClaims.roles[0]
                          )
                        ) {
                          return (
                            <NavLink
                              key={route.key}
                              to={route.path}
                              exact={route.exact}
                              onClick={() => setSidebar(false)}
                              className={({ isActive }) => {
                                return classNames(
                                  isActive && 'bg-neutral-800 text-orange-400',
                                  'bg-transparent hover:bg-neutral-700 group flex items-center p-4 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-colors duration-500 ease-in-out'
                                );
                              }}
                            >
                              <route.icon
                                className="mr-3 flex-shrink-0 h-4 w-4"
                                aria-hidden="true"
                              />
                              <div className="flex-1">{route.name}</div>
                            </NavLink>
                          );
                        }

                        return (
                          <Disclosure key={route.key}>
                            {({ open }) => (
                              <div className="text-sm">
                                <Disclosure.Button
                                  className={() => {
                                    return classNames(
                                      open && '',
                                      'text-neutral-400 flex w-full items-center justify-between bg-transparent hover:bg-neutral-700 group p-4 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-colors duration-500 ease-in-out'
                                    );
                                  }}
                                >
                                  <div className="flex items-center space-x-2">
                                    <route.icon className="h-5 w-5" />
                                    <span>{route.name}</span>
                                  </div>
                                  <ChevronUpDownIcon className="h-4 w-4" />
                                </Disclosure.Button>
                                <Transition
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 -translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 -translate-y-1"
                                >
                                  <Disclosure.Panel className="bg-neutral-900 divide-y divide-neutral-700">
                                    {_.map(route.children, (r) => {
                                      if (
                                        hasPermission(
                                          r.auth,
                                          accounts[0].idTokenClaims.roles[0]
                                        )
                                      ) {
                                        return (
                                          <NavLink
                                            key={r.key}
                                            to={r.path}
                                            onClick={() => setSidebar(false)}
                                            className={({ isActive }) => {
                                              return classNames(
                                                isActive &&
                                                  'bg-neutral-800 text-orange-400',
                                                'pl-10 bg-transparent hover:bg-neutral-700 group flex items-center p-4 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-colors duration-500 ease-in-out'
                                              );
                                            }}
                                          >
                                            <r.icon
                                              className="mr-3 flex-shrink-0 h-4 w-4"
                                              aria-hidden="true"
                                            />
                                            <div className="flex-1">
                                              {r.name}
                                              {accounts[0].idTokenClaims
                                                .roles[0] === 'admin' &&
                                                r.subtitle && (
                                                  <span className="text-neutral-500 text-[10px] ml-2">
                                                    ({r.subtitle})
                                                  </span>
                                                )}
                                            </div>
                                          </NavLink>
                                        );
                                      }
                                    })}
                                  </Disclosure.Panel>
                                </Transition>
                              </div>
                            )}
                          </Disclosure>
                        );
                      })}
                    </nav>
                  )}
                  {user && (
                    <>
                      <div className="flex items-center space-x-2 text-xs text-neutral-400 p-4">
                        <Avatar item={user} />
                        <div>
                          <div>{user.displayName}</div>
                          <div>{user.userPrincipalName}</div>
                          <div>
                            {_.capitalize(accounts[0]?.idTokenClaims.roles[0])}
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleLogout(instance)}
                        className="text-white flex w-full bg-neutral-900 hover:bg-neutral-800 backdrop-filter backdrop-blur-sm bg-opacity-50 group flex items-center justify-center text-center px-4 py-6 text-xs font-medium outline-none ring-0 focus:outline-none focus:ring-0 transition-colors duration-500 ease-in-out"
                      >
                        <span className="flex-1">Sign Out</span>
                      </button>
                    </>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Sidebar;

import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { staffEmployeesAtom } from '../../../../../atoms/atoms';

function ItemsHeader() {
  const [employees, setEmployees] = useRecoilState(staffEmployeesAtom);

  useEffect(() => {
    if (employees.checked) {
      setEmployees({
        ...employees,
        checkboxes: _.filter(employees.subset, {
          hr_approval: false,
          ceo_approval: false,
        }),
      });
    } else {
      setEmployees({
        ...employees,
        checkboxes: [],
      });
    }
  }, [employees.checked]);

  return (
    <div className="flex h-16 divide-x divide-neutral-100 text-xs border-b border-neutral-100 tracking-tight">
      <div className="w-10 flex items-center justify-center items-center justify-center">
        <input
          type="checkbox"
          checked={employees.checked}
          value={employees.checked}
          onChange={() => {
            setEmployees({
              ...employees,
              checked: !employees.checked,
            });
          }}
          className="border-neutral-200 rounded-full text-orange-400 cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none"
        />
      </div>
      <div className="flex flex-col flex-1 justify-center px-4">
        <div className="font-bold">Employees</div>
        <div className="text-neutral-500">
          Showing{' '}
          {
            matchSorter(
              employees.filter === ''
                ? employees.subset
                : _.filter(employees.subset, ['supervisor', employees.filter]),
              employees.search,
              {
                keys: ['name'],
              }
            ).length
          }{' '}
          employees of {employees.subset?.length}
        </div>
      </div>
    </div>
  );
}

export default ItemsHeader;

import { useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';

function Home() {
  const navigate = useNavigate();
  const { accounts } = useMsal();

  useMount(() => {
    if (accounts[0].idTokenClaims.roles[0] === 'admin') {
      navigate('/compensation/staff');
    } else if (accounts[0].idTokenClaims.roles[0] === 'staff') {
      navigate('/compensation/staff');
    } else if (accounts[0].idTokenClaims.roles[0] === 'human_resources') {
      navigate('/compensation/human-resources');
    } else if (accounts[0].idTokenClaims.roles[0] === 'ceo') {
      navigate('/compensation/ceo');
    } else {
      navigate('/compensation/staff');
    }
  });

  return <div>Home</div>;
}

export default Home;

import React from 'react';
import { date } from '../../../utils/helpers';
import Step from './shared-components/Step';

function Steps({ employee }) {
  const progress = () => {
    const object = {
      sup: 'In Progress',
      hr: 'Waiting for previous action to be completed',
      ceo: 'Waiting for previous action to be completed',
    };

    if (employee.supervisor_approval) {
      object.sup = 'Completed';
      object.hr = 'In Progress';
    }

    if (employee.hr_approval) {
      object.hr = 'Completed';
      object.ceo = 'In Progress';
    }

    if (employee.ceo_approval) {
      object.ceo = 'Completed';
    }

    return object;
  };

  return (
    <ol className="hidden lg:flex border-b border-t border-neutral-100 bg-neutral-50">
      <Step
        step="01"
        title="Supervisor"
        subtitle={
          employee.supervisor_approval
            ? `${progress().sup} - ${date(employee.supervisor_approval_date)}`
            : progress().sup
        }
        notes={
          employee.supervisor_approval_name &&
          `Completed by: ${employee.supervisor_approval_name || ''}`
        }
        divider={false}
        status={progress().sup}
      />
      <Step
        step="02"
        title="Human Resources"
        subtitle={
          employee.hr_approval
            ? `${progress().hr} - ${date(employee.hr_approval_date)}`
            : progress().hr
        }
        divider
        notes={
          employee.hr_approval_name &&
          `Completed by: ${employee.hr_approval_name}`
        }
        status={progress().hr}
      />
      <Step
        step="03"
        title="CEO"
        subtitle={
          employee.ceo_approval
            ? `Ready to Pay - ${date(employee.ceo_approval_date)}`
            : progress().ceo
        }
        divider
        notes={
          employee.ceo_approval_name &&
          `Completed by: ${employee.ceo_approval_name}`
        }
        status={progress().ceo}
      />
    </ol>
  );
}

export default Steps;

import { FaceFrownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import React from 'react';

function Empty() {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      className="flex flex-col flex-1 items-center justify-center text-sm uppercase text-neutral-400 space-y-2 px-4 py-10 font-mono text-center"
    >
      <FaceFrownIcon className="w-7 h-7" />
      <div>
        Nothing Found! We are sorry, no employees matched this criteria.
      </div>
    </motion.div>
  );
}

export default Empty;

import { useMsal } from '@azure/msal-react';
import { Popover, Transition } from '@headlessui/react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import is from 'is_js';
import _ from 'lodash';
import React from 'react';
import { useRecoilState } from 'recoil';
import { dashboardAtom } from '../../../../../../../atoms/atoms';
import { getUserProfilePicture } from '../../../../../../../utils/graph';
import Avatar from '../../../../../../shared-components/Avatar';

dayjs.extend(localizedFormat);

export function Row({ k, v }) {
  return (
    <dl className="flex flex-1 font-mono space-x-2">
      <dt className="flex-nowrap flex-shrink-0 text-neutral-400 font-normal">
        {k}:
      </dt>
      <dd className="text-neutral-700 font-medium">{v}</dd>
    </dl>
  );
}

function Item({ item }) {
  const [state, setState] = useRecoilState(dashboardAtom);
  const { instance } = useMsal();

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };

  const getPhoto = (email) => {
    instance
      .acquireTokenSilent({
        scopes: ['User.Read'],
      })
      .then(async (response) => {
        const photo = await getUserProfilePicture(response.accessToken, email);

        if (photo) {
          const fileReaderInstance = new FileReader();
          fileReaderInstance.readAsDataURL(photo);
          fileReaderInstance.onload = () => {
            setState({ ...state, photo: fileReaderInstance.result });
          };
        }
        setState({ ...state, photo: null });
      });
  };

  return (
    <div key={item.uuid} className="flex text-neutral-500 divide-x border-b">
      <div className="w-1/5 p-4 flex items-center justify-center">
        <input
          id={item.name}
          name={item.name}
          value={item.name}
          checked={is.existy(_.find(state.checkboxes, item))}
          onChange={(event) => {
            if (event.target.checked) {
              setState(() => ({
                ...state,
                checkboxes: [...state.checkboxes, item],
              }));
            } else {
              const filter = _.filter(state.checkboxes, (object) => {
                return object.name !== event.target.value;
              });
              setState(() => ({
                ...state,
                checkboxes: filter,
              }));
            }
          }}
          type="checkbox"
          className="border-neutral-300 disabled:opacity-50 disabled:cursor-not-allowed rounded-full text-orange-400 cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none"
        />
      </div>
      <div className="w-1/5 p-4 flex items-center font-medium text-neutral-700 bg-neutral-50 space-x-2">
        <Avatar item={item} />
        <div>
          <Popover className="relative">
            {({ open }) => (
              <>
                <Popover.Button
                  onClick={() => getPhoto(item.email)}
                  className={classNames(
                    'group inline-flex items-center rounded-md text-xs font-medium text-sky-500 hover:text-sky-400 focus:outline-none focus-visible:ring-0 focus:ring-0 transition ease-in-out duration-500',
                    open ? 'text-opacity-70' : 'text-opacity-100'
                  )}
                >
                  Name: {item.name}
                </Popover.Button>
                <Transition
                  as={React.Fragment}
                  enter="transition duration-1000 delay-150 ease-out"
                  enterFrom="transform scale-0 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-500 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-0 opacity-0"
                  beforeLeave={() => setState({ ...state, photo: null })}
                >
                  <Popover.Panel className="absolute left-1/2 z-10 mt-1 w-screen max-w-md -translate-x-1/2 transform px-4">
                    <div className="overflow-hidden rounded-xl shadow-lg">
                      <div className="relative flex space-x-4 bg-white p-4">
                        <div className="flex items-center justify-center h-16 w-16 flex-shrink-0 bg-neutral-100 rounded-full">
                          {state.photo ? (
                            <img
                              alt="Avatar"
                              src={state.photo}
                              className="h-16 w-16 rounded-full"
                            />
                          ) : (
                            <UserCircleIcon className="text-neutral-200 h-12 w-12 rounded-full" />
                          )}
                        </div>
                        <div className="space-y-2">
                          <Row k="Number" v={item.number} />
                          <Row k="Code" v={item.code} />
                          <Row k="Name" v={item.name} />
                          <Row k="E-mail" v={item.email} />
                          <Row k="Position" v={item.position} />
                          <Row k="Percent Approved" v={`${item.percent}%`} />
                          <Row
                            k="Final Compensation"
                            v={`$${numberWithCommas(item.final_compensation)}`}
                          />
                          <Row k="Coments" v={item.supervisor_comments} />
                        </div>
                      </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
          <div className="flex txt-xs font-normal text-neutral-400">
            Sup: {item.supervisor}
          </div>
          {item.ceo_approval && (
            <div
              className={classNames(
                'flex txt-xs font-medium',
                item.percent < 100 ? 'text-rose-500' : 'text-neutral-400'
              )}
            >
              Compensation: ${numberWithCommas(item.final_compensation)}
            </div>
          )}
        </div>
      </div>
      <div className="w-1/5 p-4 flex flex-col items-center justify-center text-neutral-400">
        {item.supervisor_approval_name && (
          <>
            <div>Signed by: {item.supervisor_approval_name}</div>
            <div>{dayjs(item.supervisor_approval_date).format('L LT')}</div>
          </>
        )}
      </div>
      <div className="w-1/5 p-4 flex flex-col items-center justify-center text-neutral-400">
        {item.hr_approval_name && (
          <>
            <div>Signed by: {item.hr_approval_name}</div>
            <div>{dayjs(item.hr_approval_date).format('L LT')}</div>
          </>
        )}
      </div>
      <div className="w-1/5 p-4 flex flex-col items-center justify-center text-neutral-400">
        {item.ceo_approval_name && (
          <>
            <div>Signed by: {item.ceo_approval_name}</div>
            <div>{dayjs(item.ceo_approval_date).format('L LT')}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default Item;

import { XMarkIcon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';

function Filter({ filter, onClick }) {
  return (
    <AnimatePresence>
      {!!filter && (
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{
            type: 'spring',
            duration: 2,
          }}
        >
          <button
            type="button"
            onClick={onClick}
            className="flex items-center text-cyan-500 px-2 py-1 space-x-2 text-xs bg-cyan-100 rounded-full ring-2 ring-offset-2 ring-cyan-300"
          >
            <XMarkIcon className="w-3 h-3" />
            <div>{filter}</div>
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Filter;

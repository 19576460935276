import React from 'react';

function ProRate({ payment }) {
  if (payment === 'Pro Rate') {
    return (
      <div>
        <div className="inline-block px-2 py-1 font-medium bg-orange-400 text-white rounded-sm mb-2 text-xs">
          Pro Rate
        </div>
      </div>
    );
  }

  return null;
}

export default ProRate;

import { PublicClientApplication } from '@azure/msal-browser';

export const config = {
  auth: {
    authority:
      'https://login.microsoftonline.com/3906a315-ca10-4e64-83cb-50ea735f28eb',
    clientId: '2f2db435-fcc8-4175-a905-4c415064ad8a',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
};

const instance = new PublicClientApplication(config);

export default instance;

import { Transition } from '@headlessui/react';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useRecoilValue } from 'recoil';
import { chartAtom } from '../../../../../../../atoms/atoms';

function HumChart() {
  const data = useRecoilValue(chartAtom);

  return (
    <Transition
      show={!!data}
      enter="transform transition duration-1000 delay-200"
      enterFrom="opacity-0 -translate-y-10"
      enterTo="opacity-100 translate-y-0"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 scale-100 "
      leaveTo="opacity-0 scale-95 "
      className="relative flex flex-col p-4"
    >
      <div className="absolute inset-0 flex flex-col items-center justify-center pointer-events-none">
        <div className="font-bold tracking-tight text-2xl">
          {data?.hr_approved}
          <span className="align-top text-xs">%</span>
        </div>
        <div className="text-xs text-neutral-400">Approved</div>
      </div>
      <div className="text-xs font-medium mb-4">Human Resources</div>
      <div className="h-52">
        <Doughnut
          data={{
            labels: ['Approved', 'Unapproved'],
            datasets: [
              {
                data: [data?.hr_approved, data?.hr_unapproved],
                backgroundColor: ['#06B6D4', '#E5E5E5'],
                borderWidth: 0,
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) => {
                    return `${context.label}: ${context.raw}%`;
                  },
                },
              },
            },
          }}
        />
      </div>
      <div className="flex flex-col items-start justify-start space-y-1">
        <div className="flex items-center space-x-2 text-xs text-neutral-400 tracking-tight font-light">
          <div className="h-4 w-4 bg-cyan-500 rounded-full border-2 border-cyan-600" />
          <div>{data?.hr_approved}% Approved</div>
        </div>
        <div className="flex items-center space-x-2 text-xs text-neutral-400 tracking-tight font-light">
          <div className="h-4 w-4 bg-neutral-200 rounded-full border-2 border-neutral-300" />
          <div>{data?.hr_unapproved}% Unapproved</div>
        </div>
      </div>
    </Transition>
  );
}

export default HumChart;

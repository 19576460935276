import { Dialog, Switch, Transition } from '@headlessui/react';
import axios from 'axios';
import _ from 'lodash';
import React from 'react';
import { useRecoilState } from 'recoil';
import {
  dashboardAtom,
  deleteEmployeesAtom,
} from '../../../../../../../atoms/atoms';
import Spinner from '../../../../../../shared-components/Spinner';

function RestoreEmployees() {
  const [restore, setRestore] = useRecoilState(deleteEmployeesAtom);
  const [employees, setEmployees] = useRecoilState(dashboardAtom);

  const restoreEmployees = () => {
    setRestore({ ...restore, loading: true, error: null });
    axios
      .delete('/api/remove', {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        data: {
          data: _.map(restore.employees, (e) => {
            return e.uuid;
          }),
        },
      })
      .then((response) => {
        setEmployees({
          ...employees,
          data: response.data,
        });
        setRestore({
          open: false,
          employees: [],
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        setRestore({ ...restore, loading: false, error: error.message });
      });
  };

  return (
    <Transition appear show={restore.open} as={React.Fragment}>
      <Dialog
        static
        open={restore.open}
        onClose={() => {
          setRestore({
            open: false,
            employees: [],
            loading: false,
            error: null,
          });
        }}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen p-4 text-center text-neutral-700 font-sans">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200 delay-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className="p-4">
                <div className="text-2xl font-extrabold tracking-tight mb-4 uppercase">
                  Warning
                </div>
                <div className="text-sm">
                  <span className="font-bold">
                    Are you sure you like to delete employees data?
                  </span>{' '}
                  <span className="text-neutral-500">
                    This action will delete employees data permanently and
                    irreversible. If you have any questions regarding this
                    section contact kcolon@prsciencetrust.org or
                    mjimenez@prsciencetrust.org.
                  </span>
                </div>
                <div className="space-y-2 text-xs mt-4 h-52 overflow-y-auto border rounded-xl bg-neutral-100 p-4">
                  {_.map(employees.data, (employee) => (
                    <div key={employee.uuid}>
                      <Switch.Group>
                        <div className="flex items-center space-x-2">
                          <Switch
                            checked={_.find(restore.employees, employee)}
                            onChange={() => {
                              const check = _.find(restore.employees, employee);
                              if (!check) {
                                setRestore({
                                  ...restore,
                                  employees: [...restore.employees, employee],
                                });
                              } else {
                                const array = _.filter(
                                  restore.employees,
                                  (e) => {
                                    return e !== employee;
                                  }
                                );

                                setRestore({
                                  ...restore,
                                  employees: array,
                                });
                              }
                            }}
                            className={`${
                              _.find(restore.employees, employee)
                                ? 'bg-orange-400'
                                : 'bg-neutral-200'
                            } relative inline-flex items-center h-6 rounded-full w-11 transition-colors focus:outline-none focus:ring-0`}
                          >
                            <span
                              className={`${
                                _.find(restore.employees, employee)
                                  ? 'translate-x-6'
                                  : 'translate-x-1'
                              } inline-block w-4 h-4 transform bg-white rounded-full transition-transform`}
                            />
                          </Switch>
                          <Switch.Label className="mr-4 font-medium">
                            {employee.name}
                          </Switch.Label>
                        </div>
                      </Switch.Group>
                    </div>
                  ))}
                </div>
                <div className="flex flex-col w-full mt-4">
                  {restore.error && (
                    <div className="text-center mt-2 text-rose-500 text-xs">
                      {restore.error}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-end py-4 space-x-2 bg-neutral-100 p-4">
                <button
                  type="button"
                  onClick={() => {
                    setRestore({
                      open: false,
                      employees: [],
                      loading: false,
                      error: null,
                    });
                  }}
                  className="text-xs text-white font-medium w-20 py-3 rounded bg-neutral-300 hover:bg-neutral-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={restoreEmployees}
                  className="flex items-center justify-center text-xs text-white font-medium w-20 py-3 rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  {restore.loading ? <Spinner className="h-3 w-3" /> : 'Submit'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RestoreEmployees;

import { Dialog, Transition } from '@headlessui/react';
import { CloudArrowDownIcon, XMarkIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import Papa from 'papaparse';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { dashboardAtom, restoreAtom } from '../../../../../../../atoms/atoms';
import Spinner from '../../../../../../shared-components/Spinner';

function RestoreEmployees() {
  const ref = useRef();
  const [restore, setRestore] = useRecoilState(restoreAtom);
  const [employees, setEmployees] = useRecoilState(dashboardAtom);

  const restoreEmployees = () => {
    setRestore({ ...restore, loading: true, error: null });
    axios
      .post('/api/bulk_create', restore.employees, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        setEmployees({
          ...employees,
          data: response.data,
        });
        ref.current.value = '';
        setRestore({
          open: false,
          employees: null,
          file: null,
          loading: false,
          error: null,
        });
      })
      .catch((error) => {
        setRestore({ ...restore, loading: false, error: error.message });
      });
  };

  return (
    <Transition appear show={restore.open} as={React.Fragment}>
      <Dialog
        static
        open={restore.open}
        onClose={() => {
          ref.current.value = '';
          setRestore({
            open: false,
            employees: null,
            file: null,
            loading: false,
            error: null,
          });
        }}
        className="fixed z-50 inset-0 overflow-y-auto"
      >
        <div className="min-h-screen p-4 text-center text-neutral-700 font-sans">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200 delay-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50" />
          </Transition.Child>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 -translate-y-full"
            enterTo="opacity-100 translate-y-0"
            leave="ease-in duration-300"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-full"
          >
            <div className="inline-block w-full max-w-md overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-xl">
              <div className="p-4">
                <div className="text-2xl font-extrabold tracking-tight mb-4 uppercase">
                  Warning
                </div>
                <div className="text-sm">
                  <span className="font-bold">
                    Are you sure you like to restore all employees data?
                  </span>{' '}
                  <span className="text-neutral-500">
                    This action will delete and add new employees data
                    permanently and irreversible. If you have any questions
                    regarding this section contact kcolon@prsciencetrust.org or
                    mjimenez@prsciencetrust.org.
                  </span>
                  <div className="text-left text-xs mt-4">
                    <div className="font-bold text-lg">How to?</div>
                    <div>
                      To upload the csv file you need to export the ADP Excel
                      document to a{' '}
                      <span className="font-semibold">
                        {' '}
                        CSV UTF-8 (Comma delimited) (.csv){' '}
                      </span>
                      file. Then, you need to match the columns exactly as the
                      provided csv template file. Be aware that the compensation
                      column must be a number column and can not have the
                      thousand comma separator. Please, download the template
                      for reference.{' '}
                      <Link
                        to="/template.csv"
                        target="_blank"
                        download
                        className="text-blue-500 hover:text-blue-400 ring-0 focus:ring-0 outline-none focus:outline-none"
                      >
                        Download Template
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-full mt-4">
                  <div className="h-40 bg-neutral-100 rounded-xl border-dashed border-2 border-neutral-200 cursor-pointer">
                    <div className="h-full relative flex justify-center items-center cursor-pointer">
                      <div className="absolute hover:cursor-pointer">
                        <div className="flex flex-col items-center text-xs text-neutral-400">
                          <CloudArrowDownIcon className="w-10 h-10 mb-3" />
                          <div className="uppercase">Attach your CSV file</div>
                          <div className="">*Only CSV files are allowed</div>
                        </div>
                      </div>
                      <input
                        ref={ref}
                        type="file"
                        accept=".csv"
                        className="h-full w-full opacity-0"
                        onChange={(e) => {
                          const { files } = e.target;
                          if (files) {
                            Papa.parse(files[0], {
                              header: true,
                              encoding: 'UTF-8',
                              skipEmptyLines: true,
                              complete(results, file) {
                                setRestore({
                                  ...restore,
                                  file,
                                  employees: results.data,
                                });
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                  {restore.error && (
                    <div className="text-center mt-2 text-rose-500 text-xs">
                      {restore.error}
                    </div>
                  )}
                  {restore.file && (
                    <div className="flex justify-center text-xs font-medium mt-2">
                      <div className="flex flex-initial items-center bg-green-400 rounded-full px-3 py-1 text-white">
                        <div>CSV File: {restore.file?.name}</div>
                        <button
                          type="button"
                          onClick={() => {
                            ref.current.value = '';
                            setRestore({
                              ...restore,
                              employees: null,
                              file: null,
                            });
                          }}
                          className="text-xs hover:opacity-50 ring-0 focus:ring-0 outline-none focus:outline-none transition duration-200 ease-in-out"
                        >
                          <XMarkIcon className="ml-2 w-3 h-3" />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex items-center justify-end py-4 space-x-2 bg-neutral-100 p-4">
                <button
                  type="button"
                  onClick={() => {
                    ref.current.value = '';
                    setRestore({
                      open: false,
                      employees: null,
                      file: null,
                      loading: false,
                      error: null,
                    });
                  }}
                  className="text-xs text-white font-medium w-20 py-3 rounded bg-neutral-300 hover:bg-neutral-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={restoreEmployees}
                  className="flex items-center justify-center text-xs text-white font-medium w-20 py-3 rounded bg-rose-500 hover:bg-rose-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
                >
                  {restore.loading ? <Spinner className="h-3 w-3" /> : 'Submit'}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default RestoreEmployees;

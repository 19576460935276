import { useMsal } from '@azure/msal-react';
import { Menu, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline';
import { motion } from 'framer-motion';
import _ from 'lodash';
import React from 'react';
import { useRecoilState } from 'recoil';
import { hrEmployeesAtom } from '../../../../../atoms/atoms';

function TabBar() {
  const { accounts } = useMsal();
  const [employees, setEmployees] = useRecoilState(hrEmployeesAtom);

  const tabs = [
    {
      tab: '1',
      name: 'My Employees',
      count: _.filter(employees.data, {
        supervisor_email: accounts[0].username,
      }).length,
    },
    {
      tab: '2',
      name: 'Approved',
      count: _.filter(employees.data, {
        hr_approval: true,
      }).length,
    },
    {
      tab: '3',
      name: 'Unapproved',
      count: _.filter(employees.data, {
        hr_approval: false,
        supervisor_approval: true,
      }).length,
    },
    {
      tab: '4',
      name: 'Summary',
      count: employees.data?.length,
    },
  ];

  return (
    <div>
      {/* MOBILE */}
      <div className="flex flex-1 md:hidden bg-neutral-90 rounded-t-xl border-b border-neutral-100">
        <Menu>
          <div className="w-full relative">
            <Menu.Button className="w-full h-16 pl-3 pr-10 text-sm font-medium bg-neutral-700 rounded-t-xl text-white text-left focus:ring-0 focus:outline-none focus-visible:ring-0">
              <span className="block truncate">
                {tabs[parseFloat(employees.tab) - 1].name}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronUpDownIcon
                  className="w-5 h-5 text-neutral-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={React.Fragment}
              enter="transition duration-100 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Menu.Items className="flex flex-col absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-b-xl shadow-lg max-h-60 divide-y divide-neutral-100 ring-0 focus:ring-0 outline-none focus:outline-none">
                {_.map(tabs, (t) => (
                  <Menu.Item
                    key={t.tab}
                    as="button"
                    onClick={() => {
                      setEmployees({
                        ...employees,
                        tab: t.tab,
                      });
                    }}
                    className="flex items-center h-16 flex cursor-pointer relative pl-10 pr-4 text-sm ring-0 focus:ring-0 outline-none focus:outline-none"
                  >
                    {({ active }) => (
                      <>
                        <div
                          className={`${
                            employees.tab === t.tab
                              ? 'font-medium'
                              : 'font-normal text-neutral-400 hover:text-neutral-500'
                          } flex items-center truncate w-full`}
                        >
                          <div className="flex-1 text-left">{t.name}</div>
                          <div className="text-white flex items-center justify-center w-10 h-7 text-xs rounded-full bg-orange-400">
                            {t.count}
                          </div>
                        </div>
                        {employees.tab === t.tab ? (
                          <span
                            className={`${
                              active ? 'text-amber-600' : 'text-amber-600'
                            }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                          >
                            <CheckIcon className="w-5 h-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </div>
        </Menu>
      </div>
      {/* DESKTOP */}
      <div className="hidden md:flex rounded-t-xl transform items-center bg-neutral-600 h-20 divide-x divide-neutral-500 text-xs tracking-tight shadow-md">
        {_.map(tabs, (t) => {
          return (
            <button
              key={t.tab}
              type="button"
              disabled={employees.tab === t.tab}
              onClick={() => {
                setEmployees({
                  ...employees,
                  tab: t.tab,
                  open: false,
                  employee: null,
                  search: '',
                  checked: false,
                  checkboxes: [],
                  filter: '',
                  order: 'asc',
                  over: 'name',
                });
              }}
              className={`
          ${
            employees.tab === t.tab &&
            'bg-orange-400 hover:bg-orange-400 text-white'
          } relative first:rounded-tl-xl last:rounded-tr-xl space-y-1 font-semibold flex flex-col items-center justify-center flex-1 h-20 hover:bg-neutral-700 px-4 text-white ring-0 focus:ring-0 outline-none focus:outline-none transition duration-500 ease-in-out
        `}
            >
              <div>{t.name}</div>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0 }}
                className={`${
                  employees.tab === t.tab && 'bg-orange-500'
                } absolute top-2 right-2 flex items-center justify-center w-10 h-6 text-xs rounded-full bg-neutral-700 text-white`}
              >
                {t.count}
              </motion.div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default TabBar;

const roles = {
  admin: ['admin'],
  ceo: ['admin', 'ceo'],
  human_resources: ['admin', 'human_resources'],
  staff: ['admin', 'staff'],
  user: ['admin', 'ceo', 'human_resources', 'staff', 'user'],
  guest: ['admin', 'ceo', 'human_resources', 'staff', 'user', 'guest'],
};

export default roles;

import _ from 'lodash';
import React from 'react';
import { useRecoilValue } from 'recoil';
import { dashboardSelector } from '../../../../../../atoms/selectors';
import Toolbar from '../Toolbar';
import Header from './shared-components/header/Header';
import Item from './shared-components/Item';

function Items() {
  const data = useRecoilValue(dashboardSelector);

  return (
    <>
      <div className="sticky top-20 z-40 shadow bg-white backdrop-filter backdrop-blur-sm bg-opacity-75">
        <Toolbar />
        <Header />
      </div>
      {_.map(data, (e) => {
        return <Item key={e.uuid} item={e} />;
      })}
    </>
  );
}

export default Items;

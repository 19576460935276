import { Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/solid';
import _ from 'lodash';
import { matchSorter } from 'match-sorter';
import React, { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ceoEmployeesAtom } from '../../../../../atoms/atoms';

function ItemsHeader() {
  const [employees, setEmployees] = useRecoilState(ceoEmployeesAtom);

  useEffect(() => {
    if (employees.checked) {
      setEmployees({
        ...employees,
        checkboxes: _.filter(employees.subset, {
          hr_approval: true,
          ceo_approval: false,
          supervisor_approval: true,
        }),
      });
    } else {
      setEmployees({
        ...employees,
        checkboxes: [],
      });
    }
  }, [employees.checked]);

  return (
    <div className="flex h-16 divide-x divide-neutral-100 text-xs border-b border-neutral-100 tracking-tight">
      {employees.tab !== '4' && employees.tab !== '2' && (
        <div className="w-10 flex items-center justify-center items-center justify-center">
          <input
            type="checkbox"
            checked={employees.checked}
            value={employees.checked}
            onChange={() => {
              setEmployees({
                ...employees,
                checked: !employees.checked,
              });
            }}
            className="border-neutral-200 rounded-full text-orange-400 cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none"
          />
        </div>
      )}
      <div className="flex flex-1 items-center px-4 space-x-4">
        <div className="flex flex-col flex-1 justify-center">
          <div className="font-bold">Employees</div>
          <div className="text-neutral-500">
            Showing{' '}
            {
              matchSorter(
                employees.filter === ''
                  ? employees.subset
                  : _.filter(employees.subset, [
                      'supervisor',
                      employees.filter,
                    ]),
                employees.search,
                {
                  keys: ['name'],
                }
              ).length
            }{' '}
            employees of {employees.subset?.length}
          </div>
        </div>
        <Transition
          show={!!employees.filter}
          enter="transition transform duration-1000"
          enterFrom="opacity-0 translate-x-2/3"
          enterTo="opacity-100 translate-x-0"
          leave="transition transform duration-500"
          leaveFrom="opacity-100 translate-x-0"
          leaveTo="opacity-0 translate-x-2/3"
        >
          <button
            type="button"
            onClick={() => setEmployees({ ...employees, filter: '' })}
            className="flex items-center text-neutral-500 px-2 py-1 space-x-2 text-xs bg-neutral-100 rounded-full ring-2 ring-offset-2 ring-neutral-300"
          >
            <XMarkIcon className="w-3 h-3" />
            <div>{employees.filter}</div>
          </button>
        </Transition>
      </div>
    </div>
  );
}

export default ItemsHeader;

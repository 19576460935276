import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { dashboardAtom } from '../../../../../../../../atoms/atoms';
import { dashboardSelector } from '../../../../../../../../atoms/selectors';
import Spinner from '../../../../../../../shared-components/Spinner';
import Count from './Count';
import Filter from './Filter';
import { TableHeader } from './Table';

function Header() {
  const [state, setState] = useRecoilState(dashboardAtom);
  const data = useRecoilValue(dashboardSelector);

  const handleSort = (over) => {
    if (state.over === over) {
      if (state.order === 'asc') {
        setState({
          ...state,
          over,
          order: 'desc',
        });
      } else {
        setState({
          ...state,
          over,
          order: 'asc',
        });
      }
    } else {
      setState({
        ...state,
        over,
        order: 'asc',
      });
    }
  };

  useEffect(() => {
    if (state.checked) {
      setState({
        ...state,
        checkboxes: data,
      });
    } else {
      setState({
        ...state,
        checkboxes: [],
      });
    }
  }, [state.checked]);

  return (
    <>
      <div className="flex h-16 divide-x divide-neutral-200 text-xs border-b border-neutral-200 tracking-tight">
        <div className="flex flex-1 items-center px-4 space-x-4">
          <div className="flex flex-1 space-x-4 justify-between">
            <button
              disabled={state.checkboxes.length === 0}
              onClick={() => {
                setState({
                  ...state,
                  modal: true,
                });
              }}
              type="button"
              className="flex items-center justify-center w-32 disabled:opacity-25 text-xs text-white font-medium p-3 rounded bg-sky-500 hover:bg-sky-400 transition-colors duration-500 ease-in-out ring-0 focus:ring-0 outline-none focus:outline-none"
            >
              {state.modal ? <Spinner /> : 'Export Report'}
            </button>
            <Count count={data.length} total={state.data?.length} />
          </div>
          <Filter
            filter={state.filter}
            onClick={() => setState({ ...state, filter: '' })}
          />
        </div>
      </div>
      <div className="flex items-center border-b">
        <div className="w-1/5 p-4 flex items-center justify-center h-12 bg-neutral-50 font-semibold">
          <input
            type="checkbox"
            checked={state.checked}
            value={state.checked}
            onChange={() => {
              setState({
                ...state,
                checked: !state.checked,
              });
            }}
            className="border-neutral-300 rounded-full text-orange-400 cursor-pointer ring-0 focus:ring-0 outline-none focus:outline-none"
          />
        </div>
        <TableHeader
          width="w-2/5 md:w-1/5"
          title="Employee"
          over="name"
          titleClassName="text-center"
          handleSort={() => handleSort('name')}
        />
        <TableHeader
          width="w-1/5"
          title="Supervisor"
          over="supervisor_approval"
          titleClassName="text-center"
          handleSort={() => handleSort('supervisor_approval')}
        />

        <TableHeader
          width="w-1/5"
          title="Human Resources"
          over="supervisor_approval"
          titleClassName="text-center"
          handleSort={() => handleSort('supervisor_approval')}
        />
        <TableHeader
          width="w-1/5"
          title="CEO"
          over="ceo_approval"
          titleClassName="text-center"
          handleSort={() => handleSort('ceo_approval')}
        />
      </div>
    </>
  );
}

export default Header;
